import gql from "graphql-tag";

export const CONTACT_FORM = gql`
  mutation ContactForm(
    $nameSurname: String!
    $email: String!
    $message: String!
  ) {
    contactForm(
      input: {
        clientMutationId: "contact-form"
        nameSurname: $nameSurname
        email: $email
        message: $message
      }
    ) {
      message
    }
  }
`;
export const WHOLESALE_FORM = gql`
  mutation WholesaleForm(
    $firstName: String!
    $lastName: String!
    $companyName: String!
    $subject: String!
    $email: String!
    $phone: String!
    $address: String!
    $city: String!
    $country: String!
    $message: String!
  ) {
    wholesaleForm(
      input: {
        clientMutationId: "wholesale-form"
        firstName: $firstName
        lastName: $lastName
        companyName: $companyName
        subject: $subject
        email: $email
        phone: $phone
        address: $address
        city: $city
        country: $country
        message: $message
      }
    ) {
      message
    }
  }
`;
