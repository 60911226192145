import { useQuery } from "react-apollo";
import { GET_AVAILABLE_CITIES } from "../../queries/generalQueries";

export const useAvailableCities = () => {
  const { loading, error, data } = useQuery(GET_AVAILABLE_CITIES);

  return {
    loading,
    error,
    data: data?.themeOptions?.themeOptions.cities,
  };
};
