import React from "react";
import { useScrollToTop } from "./lib/hooks/useScrollToTop";
import { useAuthContext } from "./lib/context/AuthContext/AuthContext";

import { NotificationContextProvider } from "./lib/context/NotificationContext/NotificationContextProvider";

import { Routes } from "./routes/Routes";
import { Header } from "./layout/Header/Header";
import { Footer } from "./layout/Footer/Footer";

import { useMenuSidebar } from "./lib/context/MenuSidebarContext/MenuSidebarContext";
import { WishlistSidebar } from "./layout/WishlistSidebar/WishlistSidebar";
import { CartSidebar } from "./layout/CartSidebar/CartSidebar";
import { SearchSidebar } from "./layout/SearchSidebar/SearchSidebar";
import AppLoader from "./components/shared/AppLoader/AppLoader";

import "./App.scss";

export const App = () => {
  useScrollToTop();
  const authCtx = useAuthContext();
  const menuSidebar = useMenuSidebar();

  const handleOnClick = () => {
    menuSidebar.closeSidebar("wishlist");
    menuSidebar.closeSidebar("cart");
  };

  if (authCtx.isLoading) {
    return <AppLoader />;
  }

  return (
    <div className="App" id="App">
      <div className="App__backdrop" onClick={handleOnClick} />

      <Header />

      <div className="App__main_content">
        <NotificationContextProvider>
          <Routes />
        </NotificationContextProvider>
      </div>
      <Footer />

      <WishlistSidebar />
      <CartSidebar />
      <SearchSidebar />
    </div>
  );
};
