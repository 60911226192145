import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useNotification } from "../../lib/context/NotificationContext/NotificationContext";
import { useProductSingleContext } from "../../lib/context/ProductSingleContext/ProductSingleContext";

import { Quantity } from "../shared/QuantityInput/Quantity";
import { actions, errors, formValidation } from "../../lib/translation/strings";
import { useCartContext } from "../../lib/context/CartContext/CartContext";
import { Icon } from "../shared/Icon/Icon";
import { useMenuSidebar } from "../../lib/context/MenuSidebarContext/MenuSidebarContext";
import { WishListButton } from "../shared/WishListButton/WishListButton";

export const ProductSingleActions = () => {
  const { t } = useTranslation();
  const menuCtx = useMenuSidebar();
  const notification = useNotification();
  const { items, addProduct } = useCartContext();
  const {
    inStock,
    product,
    variation,
    quantity,
    setQuantity
  } = useProductSingleContext();

  const [loading, setLoading] = useState(false);
  const [maxQuantity, setMaxQuantity] = useState(() =>
    product.type === "VARIABLE" ? 1 : product.stockQuantity
  );

  useEffect(() => {
    if (variation) {
      setMaxQuantity(
        variation.stockStatus === "IN_STOCK" ? variation.stockQuantity || 10 : 1
      );
    }
  }, [variation]);

  const getItemFromCart = () => {
    const cartItem =
      items?.filter(
        (item: any) => item.product.node?.databaseId === product.databaseId
      ) || [];

    if (cartItem.length === 0) {
      return;
    }

    if (variation) {
      return cartItem.find(
        (item: any) => item.variation.node?.id === variation.id
      );
    }

    return cartItem[0] as any;
  };

  const handleAddToCart = async () => {
    const cartItem = getItemFromCart();

    if (product.type === "SIMPLE" && !inStock) {
      notification.warning(t(errors.productNotInStock)); //This product is out of stock.
      return;
    }

    if (product.type === "VARIABLE" && !variation) {
      notification.warning(t(formValidation.selectVariation)); //Please select a variation to add it to cart.
      return;
    }

    if (product.type === "VARIABLE" && variation && !inStock) {
      notification.warning(t(errors.variationOutOfStock)); //This variation of the product is out of stock.
      return;
    }

    if (cartItem && cartItem.quantity + quantity > maxQuantity) {
      notification.warning(
        t(errors.cannotAddAmountOfStockToCart, {
          stockQuantity: maxQuantity,
          quantity: cartItem?.quantity
        })
      );
      return;
    }

    try {
      setLoading(true);

      await addProduct(
        product.databaseId,
        quantity,
        product.type === "VARIABLE" ? variation.databaseId : undefined
      );

      menuCtx.openSidebar("cart");
    } catch (error) {
      notification.warning((error as any).message, true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="ProductSingle__content__actions">
      <Quantity
        hideMobile={true}
        quantity={quantity}
        maxQuantity={maxQuantity}
        disableIncrement={quantity === maxQuantity}
        onChange={(quantity: number) => setQuantity(quantity)}
      />

      <button
        id="addToCardBtn"
        className="btn--addtocart"
        onClick={handleAddToCart}
        disabled={loading}
      >
        {loading ? (
          <Icon
            icon="loader"
            spin
            style={{
              color: "#fff",
              width: "1.25rem",
              height: "1.25rem",
              borderWidth: ".2em",
              margin: "auto"
            }}
          />
        ) : (
          <> {t(actions.addToBag)}</>
        )}
      </button>

      <WishListButton id={product.databaseId} />
    </div>
  );
};
