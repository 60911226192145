import React, { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import { Icon } from "../../components/shared/Icon/Icon";
import { Image } from "../../components/shared/Image/Image";
import { Quantity } from "../../components/shared/QuantityInput/Quantity";
import { useCartContext } from "../../lib/context/CartContext/CartContext";
import { HandleLoadingState } from "../../components/shared/HandleLoadingState/HandleLoadingState";
import { LoadingPlaceholder } from "../../components/shared/LoadingPlaceholder/LoadingPlaceholder";
import { CartNode } from "../../queries/cartQueries";
import { useMenuSidebar } from "../../lib/context/MenuSidebarContext/MenuSidebarContext";
import { removeHTMLEntities } from "../../lib/helpers/removeHTMLEntities";

import "./CartSidebar.scss";

export const getCartItemPrices = (item: CartNode) => {
  const product = item.product.node;

  if (product.type === "SIMPLE") {
    return {
      regular: product.regularPrice,
      sale: product.salePrice
    };
  }

  const variation = item.variation.node;

  return {
    regular: variation.regularPrice,
    sale: variation.salePrice
  };
};

const getStockQuantity = (item: CartNode) => {
  const product = item.product.node;

  if (product.type === "SIMPLE") {
    return product.stockQuantity || undefined;
  }

  if (product.type === "VARIABLE") {
    return item?.variation?.stockQuantity || product.stockQuantity || undefined;
  }
};

export const CartSidebar = () => {
  const ref = useRef(null);
  const cartCtx = useCartContext();
  const menuSidebar = useMenuSidebar();
  const [loading, setLoading] = useState("");

  const handleQuantityUpdate = async (value: number, item: CartNode) => {
    try {
      setLoading(item?.product?.id);
      await cartCtx.updateProductQuantity(item.key, value);
    } catch (e) {
    } finally {
      setLoading("");
    }
  };

  return (
    <div
      ref={ref}
      className={cn("CartSidebar", menuSidebar.cart && "CartSidebar--opened")}
    >
      {cartCtx.isUpdatingProduct && (
        <div className="CartSidebar--updating">
          <LoadingPlaceholder text="Ju lutem prisni..." />
        </div>
      )}

      <HandleLoadingState
        loading={cartCtx.isLoading}
        loadingPlaceholder={
          <div className="CartSidebar-loader">
            <LoadingPlaceholder />
          </div>
        }
      >
        <div className="CartSidebar-head">
          <p>Shporta</p>
          <span role="button" onClick={() => menuSidebar.closeSidebar("cart")}>
            <Icon icon="x" />
          </span>
        </div>
        {cartCtx.isEmpty ? (
          <div className="CartSidebar-empty">
            Shporta eshte e zbrazet{" "}
            <span style={{ marginLeft: 10 }}>
              <Icon icon="shopping-bag" />
            </span>
          </div>
        ) : (
          <>
            <div className="CartSidebar-body">
              <ul className="CartSidebar-list">
                {cartCtx?.items?.map((item, i) => {
                  const product = item.product?.node;

                  const stockQuantity = getStockQuantity(item);

                  return (
                    <li
                      key={`${item?.product?.id}-${i}`}
                      className="CartSidebar-list-item"
                    >
                      <NavLink to={`/product/${product?.slug}`}>
                        <div className="CartSidebar-list-item-image">
                          <Image
                            src={product?.image?.mediaItemUrl}
                            alt={product.name}
                          />
                        </div>
                      </NavLink>
                      <div className="CartSidebar-list-item-info">
                        <NavLink to={`/product/${product?.slug}`}>
                          <h4 className="mb-1">{product?.name}</h4>
                        </NavLink>

                        <span className="mb-2 d-inline-block">
                          {item.cartPrice} €
                        </span>

                        <Quantity
                          disabled={cartCtx.isUpdatingProduct}
                          loading={loading === item?.product?.id}
                          disableDecrement={item?.quantity === 1}
                          disableIncrement={item?.quantity === stockQuantity}
                          maxQuantity={stockQuantity}
                          quantity={item.quantity}
                          onChange={(value: number) =>
                            handleQuantityUpdate(value, item)
                          }
                        />
                      </div>
                      <button
                        disabled={cartCtx.isUpdatingProduct}
                        onClick={() =>
                          cartCtx.updateProductQuantity(item.key, 0)
                        }
                        className="CartSidebar-list-item-remove"
                      >
                        <Icon icon="x" />
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="CartSidebar-footer">
              <div className="CartSidebar-total">
                <span>Totali:</span>
                <strong>{removeHTMLEntities(cartCtx.total)}</strong>
                <em>Duke përfshirë transportin</em>
              </div>
              <NavLink to="/checkout">
                Vazhdo me porosinë <Icon icon="arrow-right" />
              </NavLink>
            </div>
          </>
        )}
      </HandleLoadingState>
    </div>
  );
};
