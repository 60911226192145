import React from "react";
import { Logo } from "../../../layout/Header/Logo/Logo";

const AppLoader = () => {
  return (
    <div className="App App--loading">
      <div className="animation--heart-beat">
        <Logo noLink />
      </div>
      <div className="App--loading--logos">
        <span>Fytyra</span>
        <span>Mendja</span>
        <span>Trupi</span>
        <span>Shtepia</span>
        <span>Shendeti</span>
      </div>
      <div className="App--loading--text">
        <p>Sobazaar | since 2018</p>
      </div>
    </div>
  );
};

export default AppLoader;
