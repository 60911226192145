import React from "react";
import { useQuery } from "react-apollo";
import { useTranslation } from "react-i18next";

import { fields } from "../../lib/translation/strings";

import { Product } from "../../types/productTypes";
import { GET_RELATED_PRODUCTS } from "../../queries/archive";

import { BulkLoader } from "../shared/BulkLoader/BulkLoader";
import { ProductItem } from "../ProductsList/ProductItem";
import { Alert } from "../Notifications/Alert";
import { RelatedProductsLoader } from "./RelatedProductsLoader";

import "./RelatedProducts.scss";

interface Props {
  excludedID?: number;
  category: string;
}

export const RelatedProducts = (props: Props) => {
  const { t } = useTranslation();
  const relatedProducts = useQuery(GET_RELATED_PRODUCTS, {
    variables: {
      exclude: props.excludedID,
      category: props.category
    }
  });

  if (relatedProducts.loading) {
    return (
      <div className="RelatedProducts">
        <div className="RelatedProducts__list">
          <BulkLoader length={7} component={RelatedProductsLoader} />
        </div>
      </div>
    );
  }

  if (relatedProducts.error) {
    return <Alert type="danger" message={relatedProducts.error.message} />;
  }

  return (
    <div className="RelatedProducts">
      {relatedProducts.data?.products?.nodes?.length > 0 && (
        <>
          <h3 className="RelatedProducts__title">{t(fields.relatedProducts)}</h3>
          <div className="RelatedProducts__list">
            {relatedProducts.data?.products?.nodes?.map((item: Product) => {
              return (
                <ProductItem
                  key={item.id}
                  product={item}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
