import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Container } from "reactstrap";
import { useRouter } from "../../../lib/hooks/useRouter";
import { useFetchMenu } from "./lib/useFetchMenu";
import { LoadingPlaceholder } from "../../../components/shared/LoadingPlaceholder/LoadingPlaceholder";

import "./Menu.scss";
import { actions, fields } from "../../../lib/translation/strings";

export const Menu = () => {
  const { t, i18n } = useTranslation();
  const router = useRouter();
  const { categories, loading } = useFetchMenu();
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setOpened(false);
  }, [router.location.pathname]);

  const handleSetCategories = () => {
    setOpened(!opened);
  };

  const prefix = i18n.language === "en" ? "/en" : "";

  return (
    <div className="Menu">
      <Container>
        <div className="Menu-items">
          <div className="Menu__item">
            <NavLink
              to={`${prefix}/shop`}
              activeClassName="Menu__item_inner Menu__item--active"
            >
              {t(actions.products)}
            </NavLink>
          </div>
          <div
            role="button"
            className="Menu__item"
            onClick={handleSetCategories}
          >
            <span>{t(fields.categories)}</span>
          </div>
          <div className="Menu__item">
            <NavLink
              to={`${prefix}/page/about`}
              activeClassName="Menu__item_inner Menu__item--active"
            >
              {t(actions.about)}
            </NavLink>
          </div>
          <div className="Menu__item">
            <NavLink
              to={`${prefix}/blog`}
              activeClassName="Menu__item_inner Menu__item--active"
            >
              {t(actions.blog)}
            </NavLink>
          </div>
          <div className="Menu__item">
            <NavLink
              to={`${prefix}/wholesale`}
              activeClassName="Menu__item_inner Menu__item--active"
            >
              {t(actions.wholesale)}
            </NavLink>
          </div>
          <div className="Menu__item">
            <NavLink
              to={`${prefix}/catalogue`}
              activeClassName="Menu__item_inner Menu__item--active"
            >
              {t(actions.catalogue)}
            </NavLink>
          </div>
        </div>
      </Container>

      {opened && (
        <div
          className={`Menu-dropdown ${loading ? "Menu-dropdown--loading" : ""}`}
        >
          <Container>
            {loading ? (
              <div className="d-flex justify-content-center">
                <LoadingPlaceholder />
              </div>
            ) : (
              <div className="Menu-dropdown-wrapper">
                <ul className="Menu-dropdown__list">
                  {categories.map((category) => (
                    <li key={category.id} className="Menu-dropdown__list-item">
                      <NavLink
                        to={`${prefix}/shop/${category.slug}`}
                        className="Menu-dropdown__list-item-title"
                      >
                        {category?.name}
                      </NavLink>

                      {category?.children?.nodes &&
                        category.children?.nodes.map((c) => (
                          <NavLink
                            key={c.id}
                            to={`${prefix}/shop/${category.slug}/${c.slug}`}
                          >
                            {c.name}
                          </NavLink>
                        ))}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </Container>
        </div>
      )}
    </div>
  );
};
