import React from "react";

import { Container, Row, Col } from "reactstrap";
import { useAddressQuery } from "../../lib/hooks/useAddressQuery";
import { BulkLoader } from "../../components/shared/BulkLoader/BulkLoader";
import { ProductSingleLoader } from "../ProductSingle/ProductSingleLoader";
import { useCartContext } from "../../lib/context/CartContext/CartContext";
import { ThemeRedirect } from "../../components/shared/ThemeRedirect/ThemeRedirect";
import { ApolloErrorGuard } from "../../components/shared/ApolloErrorGuard/ApolloErrorGuard";
import { HandleLoadingState } from "../../components/shared/HandleLoadingState/HandleLoadingState";
import { CheckoutForm } from "../../components/Checkout/CheckoutForm/CheckoutForm";

import "./Checkout.scss";

export const Checkout = () => {
  const cartCtx = useCartContext();

  const addressQuery = useAddressQuery();

  if (cartCtx.isEmpty) {
    return <ThemeRedirect to="/my-cart" />;
  }

  return (
    <div className="Checkout">
      <Container>
        <HandleLoadingState
          loading={addressQuery.loading}
          loadingPlaceholder={
            <Row>
              <Col xs="12" md="6">
                <BulkLoader length={1} component={ProductSingleLoader} />
              </Col>
              <Col xs="12" md="6">
                <BulkLoader length={1} component={ProductSingleLoader} />
              </Col>
            </Row>
          }
        >
          <ApolloErrorGuard error={addressQuery.error}>
            <CheckoutForm customer={addressQuery.data?.customer} />
          </ApolloErrorGuard>
        </HandleLoadingState>
      </Container>
    </div>
  );
};
