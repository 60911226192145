import { useFormik, FormikValues, FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { RegisterFields } from "../../pages/Register/Register";
import { formValidation } from "../translation/strings";

interface UseRegisterFormOptions {
  initialValues?: RegisterFields;
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<RegisterFields>
  ) => Promise<any>;
}

export const useRegisterFormik = (options: UseRegisterFormOptions) => {
  const { t } = useTranslation();

  const RegisterSchema = Yup.object().shape({
    registerName: Yup.string().required(t(formValidation.firstName)),
    registerLastName: Yup.string().required(t(formValidation.lastName)),
    registerEmail: Yup.string()
      .email(t(formValidation.validEmail))
      .required(t(formValidation.email)),
    registerCity: Yup.string().required(t(formValidation.city)),
    registerAddress: Yup.string().required(t(formValidation.address)),
    registerPassword: Yup.string()
      .min(5, t(formValidation.minPasswordLength))
      .required(t(formValidation.password)),
    registerConfirmPassword: Yup.string().oneOf(
      [Yup.ref("registerPassword")],
      t(formValidation.passwordNotMatch)
    )
  });

  return useFormik({
    initialValues: {
      registerName: "",
      registerLastName: "",
      registerEmail: "",
      registerTelephone: "",
      registerAddress: "",
      registerCity: "",
      registerPassword: "",
      registerConfirmPassword: ""
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: RegisterSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    }
  });
};

export type RegisterFormik = ReturnType<typeof useRegisterFormik>;
