import React from "react";

import "./ProductItemWatermark.scss";

interface ProductItemWatermarkProps {
  tag?: string;
}

export const ProductItemWatermark = (props: ProductItemWatermarkProps) => {
  if (!props.tag) {
    return null;
  }

  return (
    <div className="ProductItemWatermark">
      <span className="ProductItemWatermark__box ProductItemWatermark--out-of-stock">
        {props.tag}
      </span>
    </div>
  );
};
