import React from "react";
import "./ProductReviews.scss";
import { useTranslation } from "react-i18next";

import { fields } from "../../lib/translation/strings";

type ProductReviewsProps = {
  reviews: { name: string; comment: string }[];
};

export const ProductReviews = ({ reviews }: ProductReviewsProps) => {
  const { t } = useTranslation();

  console.log("reviews", reviews);
  if (!reviews || reviews.length === 0) {
    return null;
  }
  return (
    <div className="ProductReviews">
      <h3 className="ProductReviews__title">{t(fields.reviews)}</h3>
      <div className="ProductReviews__list">
        {reviews.map((review, index) => {
          return (
            <div key={`${review.name}-${index}`}>
              <h4>{review.name}</h4>
              <p>{review.comment}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
