import { useShopHandlers } from "./useShopHandlers";
import { useSearchParams } from "./useSearchParams";
import { useShopContext } from "../context/ShopContext/ShopContext";
import { ProductTaxonomyEnum } from "../../types/generalTypes";
import { useAttributeFiltersQuery } from "./useAttributeFiltersQuery";
import { useQuery } from "react-apollo";
import * as shopQueries from "../../queries/shop";
import { ShopSelectOptions } from "../../components/Shop/ui/ShopSelect/ShopSelect";

export const getTaxonomyByShopType = (type?: "category" | "brand") => {
  switch (type) {
    case "category":
      return "PRODUCT_CAT";
    case "brand":
      return "PRODUCT_BRANDS";
    default:
      return "PRODUCT_CAT";
  }
};

export const useShopFilterData = () => {
  const shopCtx = useShopContext();
  const shopHandlers = useShopHandlers();
  const searchParams = useSearchParams();
  const taxonomies = shopHandlers.prepareTaxonomyFilters(searchParams);

  const taxonomy = getTaxonomyByShopType(
    shopCtx?.data?.shopType
  ) as ProductTaxonomyEnum;

  const attributes = useAttributeFiltersQuery(
    shopCtx?.data?.slug
      ? {
          taxonomy: taxonomy,
          terms: shopCtx?.data?.slug
        }
      : undefined
  );

  const { loading, data, error, refetch: refetchProductCategories } = useQuery<
    shopQueries.GetShopProductCategoriesResponse,
    shopQueries.GetShopProductCategoriesVariables
  >(shopQueries.getProductCategories, {
    variables: {
      customTaxonomy: [
        { taxonomy: taxonomy, terms: shopCtx?.data?.slug || "" },
        ...taxonomies
      ]
    }
  });

  const handleAttributesOnChange = (
    attribute: string,
    selectedValue?: ShopSelectOptions
  ) => {
    shopHandlers.updateSearchParameters(
      selectedValue?.value || "",
      attribute,
      (data: any) => {
        const taxonomies = shopHandlers.prepareTaxonomyFilters(data);
        refetchProductCategories({
          customTaxonomy: taxonomies
        });
        if (shopCtx?.handlers?.handleAttributeChange) {
          shopCtx.handlers.handleAttributeChange(data);
        }
      }
    );
  };

  const handleCategoryChange = (categorySlug: string) => {
    if (shopCtx.handlers?.handleCategoryChange) {
      shopCtx.handlers.handleCategoryChange(categorySlug);
    }
  };

  const categories = data?.productCategories?.nodes ?? [];

  const categoryOptions = categories?.map((cat) => ({
    value: cat.slug,
    label: cat.name
  })) as ShopSelectOptions[];

  const selectedCategory = categoryOptions.find(
    (c) => c.value === shopCtx?.data?.category
  );

  const subCategoryOptions = shopCtx?.data?.category
    ? categories
        .find((cat) => cat.slug === shopCtx?.data?.category)
        ?.children?.nodes?.map((cat) => ({
          value: cat.slug,
          label: cat.name
        }))
    : [];

  const selectedSubCategory = subCategoryOptions?.find(
    (sc) => sc.value === shopCtx?.data?.subCategory
  );

  const redirectLinkPrefix = shopCtx?.data?.shopLinkPrefix
    ? `${shopCtx?.data?.shopLinkPrefix}`
    : "/shop";

  const attributesData = attributes?.data?.attributeFilters ?? [];

  return {
    loading,
    error,
    attributes,
    attributesData,
    redirectLinkPrefix,
    selectedSubCategory,
    subCategoryOptions,
    selectedCategory,
    categories,
    categoryOptions,
    handleCategoryChange,
    handleAttributesOnChange
  };
};
