import React from "react";

import { ThemeLink } from "../shared/ThemeLink/ThemeLink";

import "./EventsItem.scss";

interface Props {
  slug: string;
  title: string;
  image: string;
  big?: boolean;
}

export const EventsItem = (props: Props) => {
  return (
    <div className={`EventsItem${props.big ? " EventsItem--big" : ""}`}>
      <ThemeLink to={`/blog/${props.slug}`}>
        <span className="EventsItem__image">
          <img src={props.image} alt={props.title} />
        </span>
        <h4 className="EventsItem__title">
          <span>{props.title}</span>
        </h4>
      </ThemeLink>
    </div>
  );
};
