import React, { CSSProperties } from "react";
import { ThemeLink } from "../../../components/shared/ThemeLink/ThemeLink";
import { Icon } from "../../../components/shared/Icon/Icon";
import logo from "../../../assets/logo.svg";
import { useMenuContext } from "../../../lib/context/MenuContext/MenuContext";
import { Image } from "../../../components/shared/Image/Image";

import "./Logo.scss";

interface Props {
  noLink?: boolean;
  style?: CSSProperties;
}

export const Logo = (props: Props) => {
  const menuCtx = useMenuContext();

  return (
    <div className="Logo" style={props.style}>
      <span
        role="button"
        className="Logo__mobile-menu"
        onClick={menuCtx.openSidebar}
      >
        <Icon icon="menu" />
      </span>
      {props.noLink ? (
        <Image src={logo} alt="Sobazaar" />
      ) : (
        <ThemeLink to="/">
          <Image src={logo} alt="Sobazaar" />
        </ThemeLink>
      )}
    </div>
  );
};
