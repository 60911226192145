import React, { useState } from "react";
import { useQuery } from "react-apollo";
import { Container } from "reactstrap";
import { useTranslation } from "react-i18next";

import { errors } from "../../lib/translation/strings";

import { EventProps } from "../../types/generalTypes";
import { EventsItem } from "../../components/EventsItem/EventsItem";
import { HeadingTitle } from "../../components/shared/HeadingTitle/HeadingTitle";
import { LoadMoreButton } from "../../components/shared/LoadMoreButton/LoadMoreButton";

import { GET_EVENT_POSTS } from "../../queries/eventsQueries";

import "./Events.scss";
import { LoadingPlaceholder } from "../../components/shared/LoadingPlaceholder/LoadingPlaceholder";

export const Events = () => {
  const postsLimit = 12;
  const { t } = useTranslation();
  const { loading, data, error, fetchMore } = useQuery(GET_EVENT_POSTS, {
    variables: {
      limit: postsLimit,
      after: ""
    }
  });
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  if (loading) {
    return (
      <div className="Events my-5">
        <div className="d-flex justify-content-center align-content-center w-100">
          <LoadingPlaceholder />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="Events my-5">
        <Container>{t(errors.wentWrong)}</Container>
      </div>
    );
  }

  const events = data?.posts.nodes;

  if (events.length === 0) {
    return (
      <div className="Events my-5">
        <Container>{t(errors.nothingFound)}</Container>
      </div>
    );
  }

  const handleLoadMore = () => {
    setIsLoadingMore(true);

    fetchMore({
      variables: {
        limit: postsLimit,
        after: data.posts.pageInfo.endCursor
      },
      updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
        const newNodes = fetchMoreResult.posts.nodes;
        const pageInfo = fetchMoreResult.posts.pageInfo;

        setIsLoadingMore(false);

        if (!newNodes.length) {
          return previousResult;
        }

        return {
          posts: {
            __typename: previousResult.posts.__typename,
            nodes: [...previousResult.posts.nodes, ...newNodes],
            pageInfo
          }
        };
      }
    });
  };

  return (
    <div className="Events my-5">
      <Container>
        <HeadingTitle title="Blog" />
        <div className="Events__list mt-2">
          {events.map((item: EventProps, index: number) => {
            return (
              <EventsItem
                slug={item.slug}
                title={item.title}
                key={`${item.id}-archive-lisst`}
                image={item.featuredImage?.node?.sourceUrl}
              />
            );
          })}
        </div>
        {data?.posts.pageInfo.hasNextPage && (
          <LoadMoreButton
            onClick={() => handleLoadMore()}
            loading={loading || isLoadingMore}
          />
        )}
      </Container>
    </div>
  );
};
