import React from "react";

import "./CatalogueItem.scss";

interface Props {
  slug: string;
  title: string;
  image: string;
  link: string;
}

export const CatalogueItem = (props: Props) => {
  return (
    <div
      className={`CatalogueItem`}
      style={{
        backgroundImage: `url(${props.image})`,
        backgroundSize: "cover"
      }}
    >
      <a
        href={props.link}
        target="_blank"
        rel="noopener noreferrer"
        style={{ backgroundColor: "rgba(255,255,255,0.7)" }}
      >
        <h4 className="CatalogueItem__title">
          <span>{props.title}</span>
        </h4>
      </a>
    </div>
  );
};
