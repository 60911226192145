import React from "react";
import "./LoadingPlaceholder.scss";
import { Icon } from "../Icon/Icon";

export const LoadingPlaceholder = (props: { text?: string }) => {
  return (
    <div className="LoadingPlaceholder">
      <Icon icon="loader" spin /> <p>{props.text || "Duke u ngarkuar ..."}</p>
    </div>
  );
};
