import React from "react";
import cs from "classnames";
import { useTranslation } from "react-i18next";

import { fields } from "../../../lib/translation/strings";

import "./TransportInformation.scss";

interface Props {
  text?: string;
  cost?: string;
  right?: boolean;
}

export const TransportInformation = (props: Props) => {
  const { t } = useTranslation();
  const classes = cs("TransportInformation", {
    "TransportInformation--right": props.right
  });

  return (
    <div className={classes}>
      {props.cost ? (
        <p>
          {t(fields.transport)}: <span>{props.cost}</span>
        </p>
      ) : (
        <p>{props.text}</p>
      )}
    </div>
  );
};
