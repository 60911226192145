import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as CloseCircleIcon } from "../../assets/icons/close-circle.svg";
import { actions } from "../../lib/translation/strings";

import "./RemoveItemButton.scss";

interface Props {
  disabled?: boolean;
  onClick: () => void;
}

export const RemoveItemButton = (props: Props) => {
  const { t } = useTranslation();

  return (
    <button
      disabled={props.disabled}
      onClick={props.onClick}
      className="RemoveItemButton"
    >
      {t(actions.remove)}
      <span className="RemoveItemButton__icon">
        <CloseCircleIcon />
      </span>
    </button>
  );
};
