import React from "react";
import cs from "classnames";
import { ApolloErrorGuard } from "../../shared/ApolloErrorGuard/ApolloErrorGuard";
import { Sidebar } from "../../Sidebar/Sidebar";
import { ShopAttributes } from "../ui/ShopAttributes";
import { useShopHandlers } from "../../../lib/hooks/useShopHandlers";
import { ShopSelect } from "../ui/ShopSelect/ShopSelect";
import { useShopContext } from "../../../lib/context/ShopContext/ShopContext";

import { useShopFilterData } from "../../../lib/hooks/useShopFilterData";
import { ShopCategories } from "../ui/ShopCategories";
import { HandleLoadingState } from "../../shared/HandleLoadingState/HandleLoadingState";
import { ShopSidebarPlaceholder } from "../ui/ShopSidebarPlaceholder";
import { BulkLoader } from "../../shared/BulkLoader/BulkLoader";

import "./ShopSidebar.scss";
import { useQuery } from "react-apollo";
import { GET_BRANDS_LIST } from "../../../queries/brandsQueries";
import { useRouter } from "../../../lib/hooks/useRouter";
import { ShopFilterActions } from "../ui/ShopFilterActions";
import { Button } from "../../shared/Button/Button";
import { useTranslation } from "react-i18next";
import { actions, fields } from "../../../lib/translation/strings";

interface Props {
  className?: string;
}

export const ShopSidebar = (props: Props) => {
  const { t, i18n } = useTranslation();
  const shopHandlers = useShopHandlers();
  const shopCtx = useShopContext();
  const router = useRouter();
  const brands = useQuery(GET_BRANDS_LIST);

  const brandsList = brands?.data?.productBrands?.nodes || [];

  const shopFilterData = useShopFilterData();
  const handleOnCategoryChange = (categorySlug: string) => {
    const pathname = categorySlug
      ? `/${shopFilterData?.redirectLinkPrefix}/${categorySlug}`
      : `/${shopFilterData?.redirectLinkPrefix}`;
    shopFilterData?.handleCategoryChange(categorySlug || "");
    shopHandlers.updatePathname(pathname, true);
  };

  return (
    <Sidebar>
      <div className={cs("ShopSidebar", props.className)}>
        <Button
          color="primary"
          className={cs("ShopCategories__title mb-5")}
          style={{ border: "none", width: "100%" }}
          onClick={() =>
            router.history.push((i18n.language === "en" ? "/en" : "") + "/shop")
          }
        >
          {t(actions.clearFilters)}
        </Button>

        <div className={cs("ShopCategories__title")} style={{ border: "none" }}>
          {t(actions.orderBy)}
        </div>

        <ShopFilterActions className="ShopByCategory__filter_actions mb-5" />

        <ApolloErrorGuard error={shopFilterData.error}>
          {!shopFilterData.selectedCategory ? (
            <HandleLoadingState
              loading={shopFilterData.loading}
              loadingPlaceholder={
                <BulkLoader length={5} component={ShopSidebarPlaceholder} />
              }
            >
              <ShopCategories
                categories={shopFilterData.categories}
                onCategoryChange={handleOnCategoryChange}
              />
            </HandleLoadingState>
          ) : (
            <>
              <ShopCategories
                categories={shopFilterData.categories}
                onCategoryChange={handleOnCategoryChange}
              />

              <ShopSelect
                initialOpen
                loading={shopFilterData?.loading}
                relative
                closeOnSelect={false}
                disableMultiselect
                hideIfNoOptions
                showSelectedOption
                label={t(fields.subCategories)}
                className="ShopSidebar__product_type"
                options={shopFilterData?.subCategoryOptions}
                selected={
                  shopFilterData?.selectedSubCategory
                    ? [shopFilterData?.selectedSubCategory]
                    : undefined
                }
                onSelect={(selectOption) => {
                  const pathName = selectOption
                    ? `/${shopFilterData?.redirectLinkPrefix}/${shopCtx?.data?.category}/${selectOption?.value}`
                    : `/${shopFilterData?.redirectLinkPrefix}/${shopCtx?.data?.category}`;
                  shopFilterData?.handleCategoryChange(
                    selectOption?.value || ""
                  );
                  shopHandlers.updatePathname(pathName, true);
                }}
              />

              <ApolloErrorGuard error={shopFilterData?.attributes.error}>
                {shopFilterData?.attributesData.map((attr, index) => {
                  return (
                    <ShopAttributes
                      key={`${attr.slug}-${index}`}
                      loading={shopFilterData?.attributes.loading}
                      isLastAttribute={
                        shopFilterData?.attributesData.length - 1 === index
                      }
                      attributeTitle={attr.label}
                      attribute={attr.slug}
                      options={attr?.terms.map((t) => ({
                        isColor: attr.slug === "PANGJYRA",
                        name: t.name,
                        value: t.slug,
                        label: t.name,
                        count: t.count
                      }))}
                      onChange={(attr, selectedValue) =>
                        shopFilterData?.handleAttributesOnChange(
                          attr,
                          selectedValue
                        )
                      }
                    />
                  );
                })}
              </ApolloErrorGuard>
            </>
          )}
        </ApolloErrorGuard>

        <span className="ShopSidebar-brands">
          <HandleLoadingState
            loading={brands.loading}
            loadingPlaceholder={
              <BulkLoader length={5} component={ShopSidebarPlaceholder} />
            }
          >
            <div className={cs("ShopCategories__title")}>
              {t(fields.brands)}
            </div>
            <ApolloErrorGuard error={brands.error}>
              {brandsList?.map((brand: any) => (
                <li key={brand.id} className="Menu-dropdown__list-item">
                  <span
                    onClick={() => {
                      if (shopCtx.data.shopType === "category") {
                        router.history.push(
                          `/brands/${brand.slug}/${
                            shopCtx?.data?.category || ""
                          }`
                        );
                        return;
                      }

                      router.history.push(
                        `/brands/${brand.slug}/${shopCtx?.data?.category || ""}`
                      );
                    }}
                    className="ShopCategories__category"
                    data-active={shopCtx?.data?.slug === brand.slug}
                  >
                    {brand?.name}
                  </span>
                </li>
              ))}
            </ApolloErrorGuard>
          </HandleLoadingState>
        </span>
      </div>
    </Sidebar>
  );
};
