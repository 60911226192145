import gql from "graphql-tag";
import {
  AttributeFilter,
  ChosenAttributesInput,
  PageInfo,
  ProductTaxonomyFilterInput
} from "../types/generalTypes";
import { Product } from "../types/productTypes";

export interface GetProductsVariables {
  limit: number;
  before?: string;
  after?: string;
  category?: string;
  field?: string;
  order?: string;
  taxonomyFilter?: ProductTaxonomyFilterInput[];
}

export interface GetProductsResponse {
  products: {
    nodes: Product[];
    pageInfo?: PageInfo;
  };
}

export const getProducts = gql`
  query getProducts(
    $last: Int
    $limit: Int
    $after: String
    $before: String
    $category: String
    $field: ProductsOrderByEnum!
    $order: OrderEnum
    $taxonomyFilter: [ProductTaxonomyFilterInput]
  ) {
    products(
      last: $last
      first: $limit
      after: $after
      before: $before
      where: {
        category: $category
        orderby: { field: $field, order: $order }
        taxonomyFilter: { filters: $taxonomyFilter, relation: OR }
      }
    ) {
      nodes {
        id
        slug
        databaseId
        name

        ... on VariableProduct {
          id
          name
          salePrice(format: FORMATTED)
          regularPrice(format: FORMATTED)
          productBrands {
            nodes {
              name
              slug
            }
          }

          productWatermarks {
            nodes {
              name
              id
              slug
            }
          }
        }

        ... on SimpleProduct {
          id
          name
          salePrice
          regularPrice
          productBrands {
            nodes {
              name
              slug
            }
          }

          productWatermarks {
            nodes {
              name
              id
              slug
            }
          }
        }

        image {
          id
          mediaItemUrl
          sourceUrl(size: _630X500)
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export interface ShopProductCategory {
  id: string;
  link: string;
  slug: string;
  name: string;
  image: {
    sourceUrl: string;
  };
  children: {
    nodes: ShopProductCategory[];
  };
}

export interface GetShopProductCategoriesVariables {
  customTaxonomy?: ChosenAttributesInput[];
}

export interface GetShopProductCategoriesResponse {
  productCategories: {
    nodes: ShopProductCategory[];
  };
}

export const getProductCategories = gql`
  query getProductCategories($customTaxonomy: [ChosenAttributesInput]) {
    productCategories(
      where: { hideEmpty: true, exclude: "15", customTaxonomy: $customTaxonomy }
    ) {
      nodes {
        id
        link
        name
        slug
        image {
          sourceUrl
        }
        children(where: { hideEmpty: true, customTaxonomy: $customTaxonomy }) {
          nodes {
            id
            name
            link
            slug
          }
        }
      }
    }
  }
`;

export interface GetAttributeFiltersVariables {
  chosenAttributes: ChosenAttributesInput[];
}

export interface GetAttributeFiltersResponse {
  attributeFilters: AttributeFilter[];
}

export const getAttributeFilters = gql`
  query getAttributeFilters($chosenAttributes: [ChosenAttributesInput]) {
    attributeFilters(chosenAttributes: $chosenAttributes) {
      id
      name
      label
      slug
      terms {
        id
        count
        name
        slug
      }
    }
  }
`;
