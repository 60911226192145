import React from "react";
import { useQuery } from "react-apollo";
import Slider from "react-slick";
import {
  GET_TYPE_A_CAMPAIGNS,
  CampaignTypeAPost
} from "../../../queries/campaignsQueries";
import { HomepageSlideLoader } from "./HomepageSlideLoader";
import { ApolloErrorGuard } from "../../shared/ApolloErrorGuard/ApolloErrorGuard";
import { HandleLoadingState } from "../../shared/HandleLoadingState/HandleLoadingState";
import { Button } from "../../shared/Button/Button";
import { Icon } from "../../shared/Icon/Icon";
import { useRouter } from "../../../lib/hooks/useRouter";

import "./HomepageSlide.scss";

const CustomArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <span
      role="button"
      className={`CustomArrow ${className}`}
      style={{
        width: 40,
        height: 40,
        justifyContent: "center",
        alignItems: "center",
        ...style,
        display: "flex !important",
        background: "rgba(238, 238, 238, 0.8)",
        borderRadius: 8,
        "&:hover": {
          borderColor: "#ff681c"
        }
      }}
      onClick={onClick}
    >
      <Icon icon="chevron-right" style={{ color: "#000" }} />
    </span>
  );
};

const CustomArrowLeft = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <span
      role="button"
      className={`CustomArrow ${className}`}
      style={{
        width: 40,
        height: 40,
        justifyContent: "center",
        alignItems: "center",
        ...style,
        display: "flex !important",
        background: "rgba(238, 238, 238, 0.8)",
        borderRadius: 8,
        "&:hover": {
          borderColor: "#ff681c"
        }
      }}
      onClick={onClick}
    >
      <Icon icon="chevron-left" style={{ color: "#000" }} />
    </span>
  );
};

export const HomepageSlide = () => {
  const router = useRouter();
  const slider = useQuery(GET_TYPE_A_CAMPAIGNS);

  const sliderData =
    slider.data?.generalOptions?.generalOptions?.homeAds
      ?.a1BannerSliderCampaigns ?? [];

  const settings = {
    infinite: true,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 3000,
    fade: false,
    prevArrow: <CustomArrowLeft />,
    nextArrow: <CustomArrow />
  };

  const handleOnScrollDown = () => {
    const element = document.getElementById("home-brands");
    if (!element) {
      return;
    }
    element.scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
  };

  return (
    <div className="HomepageSlide">
      <HandleLoadingState
        loading={slider.loading}
        loadingPlaceholder={<HomepageSlideLoader />}
      >
        <ApolloErrorGuard error={slider.error}>
          <Slider {...settings}>
            {sliderData.map((item: CampaignTypeAPost) => {
              return (
                <div
                  key={item.id}
                  className="HomepageSlide__item "
                  onClick={() =>
                    router.history.push(item.campaignsOptions.link || "/shop")
                  }
                >
                  <img src={item?.featuredImage?.node?.mediaItemUrl} alt="" />
                </div>
              );
            })}
          </Slider>
        </ApolloErrorGuard>
      </HandleLoadingState>

      <Button
        color="primary"
        className="HomepageSlide__btn-down"
        onClick={handleOnScrollDown}
      >
        <Icon icon="chevron-down" />
      </Button>
    </div>
  );
};
