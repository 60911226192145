import gql from "graphql-tag";

export interface CatalogueProps {
  id: string;
  slug: string;
  title: string;
  featuredImage: {
    node: {
      sourceUrl: string;
    };
  };
  catalogueOptions: {
    catalogue: {
      mediaItemUrl: string;
    };
  };
}

export const GET_CATALOGUE_POSTS = gql`
  query getCataloguePosts($limit: Int, $after: String) {
    catalogues(first: $limit, after: $after) {
      nodes {
        id
        title
        featuredImage {
          node {
            id
            sourceUrl(size: _630X500)
          }
        }
        catalogueOptions {
          catalogue {
            mediaItemUrl
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
