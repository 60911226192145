import React from "react";
import { Container } from "reactstrap";
import { Icon } from "../../components/shared/Icon/Icon";
import { Link } from "react-router-dom";

import "./Footer.scss";
import { fields } from "../../lib/translation/strings";
import { useTranslation } from "react-i18next";

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="Footer">
      <Container>
        <div className="Footer__items">
          <ul className="Footer__list">
            <li className="Footer__list-head">{t(fields.otherLinks)}</li>
            <li>
              <Link to="/page/transporti">{t(fields.transport)}</Link>
            </li>
            <li>
              <Link to="/contact">{t(fields.contact)}</Link>
            </li>
            <li>
              <Link to="/page/kushtet-e-blerjes">
                {t(fields.termsOfPurchase)}
              </Link>
            </li>
            <li>
              <Link to="/page/politikat-e-privatesise">
                {t(fields.privacyPolicy)}
              </Link>
            </li>
          </ul>
          <ul className="Footer__list">
            <li className="Footer__list-head">Social Media</li>
            <li style={{ display: "flex" }}>
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon="facebook" />
              </a>
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                className="ml-3"
              >
                <Icon icon="instagram" />
              </a>
            </li>
          </ul>

          <ul className="Footer__list">
            <li className="Footer__list-head">{t(fields.contact)}</li>
            <li>
              <a href="tel:+38349123123">+38349123123</a>
            </li>
            <li>
              <a href="mailto:info@sobazaar.com">info@sobazaar.com</a>
            </li>
          </ul>
        </div>
      </Container>
      <div className="Footer__copyright">
        <span>Copyright © Sobazaar {new Date().getFullYear()}</span>
      </div>
    </div>
  );
};
