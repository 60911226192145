import gql from "graphql-tag";
import { PageInfo } from "../types/generalTypes";
import { Product } from "../types/productTypes";

export interface SearchProductVariables {
  limit: number;
  term: string;
  after: string;
}

export interface SearchAutocompleteProductsResponse {
  products: {
    nodes: Product[];
    pageInfo: PageInfo;
  };
}

export const searchAutocompleteProducts = gql`
  query searchAutocompleteProducts($term: String, $limit: Int, $after: String) {
    products(first: $limit, after: $after, where: { search: $term }) {
      nodes {
        id
        slug
        databaseId
        name
        onSale
        image {
          id
          mediaItemUrl
          sourceUrl(size: _300X210)
        }
        ... on SimpleProduct {
          id
          salePrice
          regularPrice
        }
        ... on VariableProduct {
          id
          salePrice
          regularPrice
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const searchProducts = gql`
  query searchProducts($term: String, $limit: Int, $after: String) {
    products(first: $limit, after: $after, where: { search: $term }) {
      nodes {
        id
        slug
        databaseId
        name
        ... on VariableProduct {
          name
          regularPrice(format: FORMATTED)
          salePrice(format: FORMATTED)
        }
        ... on SimpleProduct {
          name
          salePrice
          regularPrice
        }
        image {
          id
          mediaItemUrl
          sourceUrl(size: _300X210)
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
