import React from "react";
import { useTranslation } from "react-i18next";
import { Form, FormGroup, FormFeedback } from "reactstrap";
import { Input } from "../../components/shared/Input/Input";

import { actions, formInputs } from "../../lib/translation/strings";

// import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";
// import { useNotification } from "../../lib/context/NotificationContext/NotificationContext";

import { LoginFormik } from "../../lib/formik/useLoginFormik";
import { ThemeLink } from "../../components/shared/ThemeLink/ThemeLink";
import { Button } from "../../components/shared/Button/Button";

import "./Login.scss";

interface Props {
  isLoading: boolean;
  formik: LoginFormik;
}

export const LoginForm = (props: Props) => {
  // const authCtx = useAuthContext();
  const { t } = useTranslation();
  // const notificationCtx = useNotification();
  //
  // const loginError =
  //   authCtx.loginError &&
  //   typeof authCtx.loginError === "string" &&
  //   (authCtx.loginError.includes("invalid_email") ||
  //     authCtx?.loginError.includes("incorrect_password"));
  //
  // useEffect(() => {
  //   if (loginError) {
  //     notificationCtx.danger(t(errors.wrongEmailOrPassword), false);
  //   }
  // }, [loginError]); // eslint-disable-line react-hooks/exhaustive-deps

  const { isLoading, formik } = props;

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FormGroup>
        <Input
          type="email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          placeholder={t(formInputs.email)}
          invalid={!!formik.errors.email && formik.touched.email}
        />
        <FormFeedback>{formik.errors.email}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Input
          name="password"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          placeholder={t(formInputs.password)}
          invalid={!!formik.errors.password && formik.touched.password}
        />
        <FormFeedback>{formik.errors.password}</FormFeedback>
      </FormGroup>

      <Button
        color="primary"
        type="submit"
        className="Login__submit w-100-mobile"
      >
        {isLoading ? t(actions.loggingIn) : "Kyçu"}
      </Button>

      <div className="Login__links">
        <span>
          Ende nuk keni një profil ?{" "}
          <ThemeLink to="/register" className="Login__wrapper-text">
            Krijoni llogarinë!
          </ThemeLink>
        </span>
        <ThemeLink to="/forgot-password" className="Login__wrapper-text">
          Kam harruar fjalëkalimin
        </ThemeLink>
      </div>
    </Form>
  );
};
