import React from "react";
import cs from "classnames";
import { Container } from "reactstrap";
import { useTranslation } from "react-i18next";

import { errors } from "../../lib/translation/strings";
import { useProductSingleContext } from "../../lib/context/ProductSingleContext/ProductSingleContext";

import { Alert } from "../../components/Notifications/Alert";
import { RelatedProducts } from "../../components/RelatedProducts/RelatedProducts";
import { ApolloErrorGuard } from "../../components/shared/ApolloErrorGuard/ApolloErrorGuard";
import { HandleLoadingState } from "../../components/shared/HandleLoadingState/HandleLoadingState";
import { ProductSingleContextProvider } from "../../lib/context/ProductSingleContext/ProductSingleContextProvider";
import { ProductSimpleAttributes } from "../../components/ui/ProductSimpleAttributes";
import { ProductSingleSlider } from "../../components/ProductSingleSlider/ProductSingleSlider";
import { ProductSingleVariations } from "../../components/ProductSingleVariations/ProductSingleVariations";
import { ProductSingleContent } from "../../components/ProductSingleContent/ProductSingleContent";
import { ProductSingleDetails } from "../../components/ProductSingleDetails/ProductSingleDetails";
import { ProductSingleActions } from "../../components/ProductSingleActions/ProductSingleActions";
import { LoadingPlaceholder } from "../../components/shared/LoadingPlaceholder/LoadingPlaceholder";
import { ProductReviews } from "../../components/ProductReviews/ProductReviews";

const ProductSingleInner = () => {
  const { t } = useTranslation();
  const { loading, error, product } = useProductSingleContext();
  return (
    <>
      <div className={cs("ProductSingle")}>
        <Container>
          <HandleLoadingState
            loading={loading}
            loadingPlaceholder={
              <div
                style={{
                  minHeight: 400,
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <LoadingPlaceholder />
              </div>
            }
          >
            <ApolloErrorGuard error={error}>
              {!product ? (
                <Alert type="warning" message={t(errors.noProductFound)} />
              ) : (
                <div className="ProductSingle__wrapper">
                  <ProductSingleSlider />
                  <div className="ProductSingle__content">
                    <ProductSingleContent />
                    {product.type === "VARIABLE" && <ProductSingleVariations />}
                    {product.type === "SIMPLE" && <ProductSimpleAttributes />}
                    <ProductSingleDetails />
                    <ProductSingleActions />
                  </div>
                </div>
              )}
              <ProductReviews reviews={product?.productOptions?.reviews} />
              <RelatedProducts
                excludedID={product?.databaseId}
                category={product?.productCategories.nodes[0]?.slug || ""}
              />
            </ApolloErrorGuard>
          </HandleLoadingState>
        </Container>
      </div>
    </>
  );
};

export const ProductSingle = () => {
  return (
    <ProductSingleContextProvider>
      <ProductSingleInner />
    </ProductSingleContextProvider>
  );
};
