export const formInputs = {
  name: "emri",
  email: "email",
  address: "adresa",
  message: "mesazhi",
  lastName: "mbiemri",
  password: "fjalëkalimi",
  yourComment: "komenti juaj",
  username: "emri i përdoruesit",
  newPassword: "fjalëkalimi i ri",
  telephoneNumber: "numri i telefonit",
  confirmPassword: "konfirmo fjalëkalimin",
  commentPlaceholder: "na shkruani këtu për më shumë informata",
  firstName: "emri",
  companyName: "emri i kompanisë",
  subject: "subjekti",
  phone: "telefoni",
  city: "qyteti",
  country: "shteti",
  postcode: "kodi postar"
};

export const formValidation = {
  field: "kjo fushë është  nevojshme",
  city: "qyteti është i nevojshëm",
  country: "shteti është i nevojshëm",
  confirmEmail: "rishkruaj emailin",
  address: "adresa është e nevojshme",
  firstName: "emri është i nevojshëm",
  lastName: "mbiemri është i nevojshëm",
  email: "ju lutemi shënoni emailin tuaj",
  differentEmail: "emaili nuk përputhet",
  message: "ju lutemi shkruani mesazhin tuaj",
  validEmail: "ju lutemi shënoni një email valide",
  phone: "numri i telefonit është i nevojshëm",
  password: "ju lutemi shënoni fjalëkalimin tuaj",
  passwordNotMatch: "fjalëkalimi nuk është i njejtë",
  paymentMethod: "ju lutemi zgjidhni mënyrën e pagesës",
  selectVariation: "ju lutemi zgjidhni një nga opsionet",
  minPasswordLength: "fjalëkalimi duhet të përmbajë më së paku 5 karaktere",
  termsAndConditions:
    "ju lutemi konfirmoni se a pajtoheni me termet dhe kushtet"
};

export const formMessages = {
  profileUpdated: "të dhënat tuaja janë përditësuar",
  profileNotUpdated:
    "përditësimi i të dhënave nuk është kompletuar... Ju lutemi provoni përseri"
};

export const actions = {
  clearFilters: "largo filterat",
  login: "kyçu",
  save: "ruaj",
  show: "shfaq",
  send: "dërgo",
  logOut: "dil",
  apply: "apliko",
  remove: "largo",
  search: "kërko",
  clean: "pastro",
  filter: "filtro",
  approve: "aprovim",
  register: "regjistrohu",
  orderBy: "rendit sipas",
  seeMore: "shiko më shumë",
  loggingIn: "duke u kyçur",
  saving: "duke u ruajtuar",
  loading: "duke u ngarkuar",
  addToBag: "shto në shportë",
  addProducts: "shto produkte",
  processing: "duke u procesuar",
  createAccount: "krijo llogari",
  backToHome: "kthehu në ballinë",
  findProduct: "Kërko produktin ...",
  subscribe: "abonohu për të rejat",
  goBackToStores: "kthehu te butikat",
  goBackToBrands: "kthehu te brendet",
  removingCoupon: "duke larguar kuponin",
  retypePassword: "rivendos fjalëkalimin",
  selectYourCity: "zgjedh qytetin tënd",
  selectYourCountry: "zgjedh shtetin tënd",
  continueWithPurchase: "vazhdo me blerjen",
  seeAllResults: "Shiko të gjitha rezultatet",
  updatingShippingMethod: "duke përditësuar mënyrën e dërgesës",
  products: "produktet",
  about: "për ne",
  blog: "blog",
  wholesale: "wholesale",
  catalogue: "katalogu",
  teRejat: "te rejat",
  neZbritje: "ne zbritje",
  visitBlog: "vizito blogun"
};

export const fields = {
  selectOneOfTheOptions: "selekto njërën nga opsionet",
  price: "çmimi",
  city: "qyteti",
  total: "totali",
  cart: "shporta",
  order: "porosia",
  stores: "butikat",
  address: "Adresa",
  status: "statusi",
  brands: "brendet",
  events: "eventet",
  market: "marketi",
  country: "shteti",
  quantity: "sasia",
  barcode: "barkodi",
  myList: "deshirat",
  billing: "faturimi",
  seeMore: "më shumë",
  product: "produkti",
  shipping: "dërgesa",
  article: "artikull",
  articles: "artikuj",
  contact: "kontakti",
  viewAll: "të gjitha",
  privacy: "Privatësia",
  subTotal: "nën total",
  zipCode: "kodi postar",
  cuponCode: "kodi i kuponit",
  transport: "transporti",
  categories: "kategoritë",
  subCategories: "nën kategoritë",
  description: "përshkrimi",
  myAccount: "Llogaria ime",
  seeCart: "shiko shportën",
  faq: "pyetjet e shpeshta",
  jobVacancy: "konkurs pune",
  myOrders: "Porositë e mia",
  verification: "verifikimi",
  yourAccount: "llogaria ime",
  orderDate: "data e porosisë",
  myProducts: "produktet e mia",
  includeTaxes: "përfshirë tvsh",
  relatedProducts: "të ngjashme",
  shippingCost: "çmimi i dërgesës",
  productType: "llojet e produkteve",
  paymentMethod: "mënyra e pagesës",
  usedCoupons: "kuponat e përdorur",
  orderDetails: "detajet e porosisë",
  termsOfPurchase: "kushtet e blerjes",
  bagSummary: "përmbledhja e shportës",
  billingAddress: "adresa e faturimit",
  shippingAddress: "adresa e dërgesës",
  orderSummary: "përmbledhja e porosisë",
  shippingMethod: "mënyra e transportit",
  billingAndShipping: "faturimi dhe dërgimi",
  personalInformation: "të dhënat personale",
  searchResultsFor: "rezultatet e kërkimit për",
  copyrights: "të gjitha të drejtat e autorizuara",
  categorizeByAlphabet: "kategorizimi sipas alfabetit",
  male: "meshkuj",
  female: "femra",
  children: "fëmijë",
  all: "të gjitha",
  otherLinks: "linka tjere",
  privacyPolicy: "politikat e privatsise",
  reviews: "komentet"
};

export const errors = {
  noStores: "nuk ka butika",
  noBrands: "nuk ka brende",
  noOptions: "nuk ka opsione",
  noProducts: "nuk ka produkte",
  requiredCoupon: "kuponi është i domosdoshëm",
  noProductsFound: "Asnjë rezultat nuk është gjetur!",
  noStoresFound: "për momentin nuk gjendet ky butik",
  noProductFound: "produkti i kërkuar nuk është gjetur",
  noProductsDescription: "për momentin nuk ka produkte",
  noOrdersFound: "ju momentalisht nuk keni ndonjë porosi",
  noBrandsDescription: "për momentin nuk gjendet ky brend",
  wrongEmailOrPassword: "emaili ose fjalëkalimi janë gabim",
  productNotInStock: "produkti i zgjedhur nuk është në stok",
  variationOutOfStock: "opsioni i zgjedhur nuk është në stok",
  nothingFound: "nuk është gjetur asnjë artikull për këtë kategori",
  maximumAmount: "keni arritur maksimumin e stokut të këtij produkti",
  noStoreProducts: "ne Butikun e kerkuar nuk ka produkte te listuara",
  noBrandsProducts: "ne Brendin e kerkuar nuk ka produkte te listuara",
  wentWrong: "si duke diqka ka shkuar gabim, ju lutemi provoni perseri...!",
  orderNotFound:
    "porosia që po e kërkoni nuk u gjetë vizitoni listen e porosive",
  cannotAddAmountOfStockToCart: "errorsCannotAddAmountOfStockToCart"
};

export const messages = {
  free: "falas",
  allRight: "në rregull",
  agreeWith: "pranoj",
  allStores: "te gjitha dyqanet",
  allBrands: "te gjitha brendet",
  buyByStore: "blej sipas dyqanit",
  buyByBrand: "blej sipas brendit",
  purchaseTerms: "kushtet e blerjes",
  allProducts: "të gjitha produktet",
  buyInMarket: "blej në L'kurë",
  pageNotFound: "kjo faqe nuk ekziston",
  buyByCategory: "blej sipas kategorisë",
  singleProductAdded: "produkti u shtua",
  forgotPassword: "kam harruar fjalëkalimin",
  outOfStock: "messagesOutOfStock",
  limitedProducts: "messagesLimitedProducts",
  noFaq: "momentalisht nuk ka ndonjë pyetje",
  shippingInformation: "të dhënat e dërgesës",
  toAnotherAddress: "dërgo në një adresë tjetër",
  contactSuccess: "ju faleminderit për mesazhin",
  registerSuccess: "jeni regjistruar me sukses",
  soldOut: "e shitur",
  productAdded: "keni shtuar një produkt në shportë",
  noProductOnTheList: "ju momentalisht nuk keni ndonjë produkt në listë",
  noProductsOnWishlist: "ju momentalisht nuk keni ndonjë produkt në listë",
  resetPasswordSuccess:
    "keni ndryshuar fjalëkalimin me sukses, ju lutemi kyçuni këtu",
  orderConfirmed:
    "porosia juaj u verifikua dhe së shpejti do të informoheni përmes emailit",
  resetPasswordDescription:
    "vendoseni fjalëkalimin e ri dhe pastaj mund të kyçeni me kredenciale të reja",
  resetPasswordInstruction:
    "në emailin që keni dhënë këtu do të pranoni linkun ku  duhet t'i ndiqni hapat për rivendosje të fjalëkalimit",
  createAccountMessage:
    "në mënyrë që t'ju lehtësojmë procesin e blerjes, duhet të hapni llogari. Hapja e llogarisë nënkupton dhënie të informatave personale.",
  resetPasswordMessage:
    "ju kemi dërguar një email për t'ju udhëtuar se si ta rivendosni fjalëkalimin e ri. Nëse rivendosni fjalëkalimin e ri, atëherë kyçuni në butonin më poshtë"
};

export const commonTexts = {};
