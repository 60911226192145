import React from "react";
import { useTranslation } from "react-i18next";
import { Col, FormFeedback, FormGroup, Label } from "reactstrap";
import { Input } from "../../shared/Input/Input";

import { fields, formInputs } from "../../../lib/translation/strings";

import { HeadingTitle } from "../../shared/HeadingTitle/HeadingTitle";
import { ProfileAddressFormik } from "../../../lib/formik/profile/useProfileAddressFormik";
import { SelectCountry } from "../../shared/SelectCountry/SelectCountry";

interface Props {
  formik: ProfileAddressFormik;
}

export const ProfileAddressShipping = (props: Props) => {
  const { formik } = props;
  const { t } = useTranslation();

  return (
    <>
      <Col xs="12" sm="12">
        <HeadingTitle title={t(fields.shippingAddress)} />
      </Col>

      <Col xs="12">
        <FormGroup className="form-group">
          <Label for="name">{t(formInputs.name)}</Label>
          <Input
            type="text"
            name="shipping.firstName"
            placeholder={t(formInputs.name)}
            onChange={formik.handleChange}
            value={formik.values.shipping.firstName || ""}
          />
        </FormGroup>
      </Col>

      <Col xs="12">
        <FormGroup className="form-group">
          <Label for="name">{t(formInputs.lastName)}</Label>
          <Input
            type="text"
            name="shipping.lastName"
            onChange={formik.handleChange}
            placeholder={t(formInputs.lastName)}
            value={formik.values.shipping.lastName || ""}
          />
        </FormGroup>
      </Col>

      <Col xs="12">
        <FormGroup className="form-group">
          <Label for="name">{t(formInputs.address)}</Label>
          <Input
            type="text"
            name="shipping.address1"
            onChange={formik.handleChange}
            placeholder={t(formInputs.address)}
            value={formik.values.shipping.address1 || ""}
          />
        </FormGroup>
      </Col>

      <Col xs="12">
        <FormGroup className="form-group">
          <Label for="shippingPostcode">{t(formInputs.postcode)}</Label>
          <Input
            type="text"
            id="shippingPostcode"
            name="shipping.postcode"
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.shipping.postcode}
            invalid={
              !!formik.errors.shipping?.postcode &&
              formik.touched.shipping?.postcode
            }
          />
          <FormFeedback>{formik.errors.shipping?.postcode}</FormFeedback>
        </FormGroup>
      </Col>
      <Col xs="12">
        <FormGroup className="form-group">
          <Label for="shippingCity">{t(formInputs.city)}</Label>
          <Input
            type="text"
            id="shippingCity"
            name="shipping.city"
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.shipping.city}
            invalid={
              !!formik.errors.shipping?.city && formik.touched.shipping?.city
            }
          />
          <FormFeedback>{formik.errors.shipping?.city}</FormFeedback>
        </FormGroup>
      </Col>
      <Col xs="12">
        <FormGroup className="form-group">
          <Label for="shippingCountry">{t(fields.country)}</Label>
          <SelectCountry
            name="shipping.country"
            initialValue={formik.values.shipping.country}
            hasError={
              !!formik.errors.shipping?.country &&
              formik.touched.shipping?.country
            }
            errorMessage={formik.errors.shipping?.country}
            onSelect={(country: string) => {
              formik.setFieldValue("shipping", {
                ...formik.values.shipping,
                country
              });
              formik.setFieldTouched("shipping");
            }}
            onComplete={(loading) => {
              return;
            }}
          />
        </FormGroup>
      </Col>
    </>
  );
};
