import gql from "graphql-tag";

export interface Testimonial {
  id: string;
  title: string;
  content: string;
  featuredImage: {
    node: {
      id: string;
      mediaItemUrl?: string;
    };
  };
}

export interface GetTestimonialsResponse {
  testimonials: {
    nodes: Testimonial[];
  };
}

export const getTestimonialsQuery = gql`
  query MyQuery {
    testimonials {
      nodes {
        id
        title
        content
        featuredImage {
          node {
            id
            mediaItemUrl
          }
        }
      }
    }
  }
`;
