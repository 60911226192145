import React from "react";

import { ReactComponent as UserIcon } from "../../../assets/icons/user.svg";
import { ReactComponent as HeartIcon } from "../../../assets/icons/heart.svg";
import { ReactComponent as ShoppingCartIcon } from "../../../assets/icons/shopping-cart.svg";

import "./HeadingTitle.scss";

type TitleIcon = "heart" | "cart" | "user";

interface TitleProps {
  title: string;
  icon?: TitleIcon;
}
const getIcon = (icon?: TitleIcon) => {
  switch (icon) {
    case "heart":
      return HeartIcon;
    case "cart":
      return ShoppingCartIcon;
    case "user":
      return UserIcon;
    default:
      return null;
  }
};

export const HeadingTitle = (props: TitleProps) => {
  const Icon = getIcon(props.icon);
  return (
    <div className="HeadingTitle">
      <h1>
        {props.title}
        {Icon && <Icon className="HeadingTitle__icon" />}
      </h1>
    </div>
  );
};
