import React from "react";
import { useTranslation } from "react-i18next";
import { Col, FormGroup, Label, FormFeedback } from "reactstrap";
import { Input } from "../../shared/Input/Input";

import { fields, formInputs } from "../../../lib/translation/strings";

import { HeadingTitle } from "../../shared/HeadingTitle/HeadingTitle";
import { ProfileAddressFormik } from "../../../lib/formik/profile/useProfileAddressFormik";
import { SelectCountry } from "../../shared/SelectCountry/SelectCountry";

interface Props {
  formik: ProfileAddressFormik;
}

export const ProfileAddressBilling = (props: Props) => {
  const { formik } = props;
  const { t } = useTranslation();

  return (
    <>
      <Col xs="12" sm="12">
        <HeadingTitle title={t(fields.billingAddress)} />
      </Col>

      <Col xs="12">
        <FormGroup className="form-group">
          <Label for="name">{t(formInputs.name)}</Label>
          <Input
            type="text"
            name="billing.firstName"
            onChange={formik.handleChange}
            value={formik.values.billing.firstName || ""}
            invalid={
              !!formik.errors.billing?.firstName &&
              formik.touched.billing?.firstName
            }
          />
          <FormFeedback>{formik.errors.billing?.firstName}</FormFeedback>
        </FormGroup>
      </Col>

      <Col xs="12">
        <FormGroup className="form-group">
          <Label for="name">{t(formInputs.lastName)}</Label>
          <Input
            type="text"
            name="billing.lastName"
            onChange={formik.handleChange}
            value={formik.values.billing.lastName || ""}
            invalid={
              !!formik.errors.billing?.lastName &&
              formik.touched.billing?.lastName
            }
          />
          <FormFeedback>{formik.errors.billing?.lastName}</FormFeedback>
        </FormGroup>
      </Col>

      <Col xs="12">
        <FormGroup className="form-group">
          <Label for="name">{t(formInputs.address)}</Label>
          <Input
            type="text"
            name="billing.address1"
            onChange={formik.handleChange}
            placeholder={t(formInputs.address)}
            value={formik.values.billing.address1 || ""}
            invalid={
              !!formik.errors.billing?.address1 &&
              formik.touched.billing?.address1
            }
          />
          <FormFeedback>{formik.errors.billing?.address1}</FormFeedback>
        </FormGroup>
      </Col>

      <Col xs="12">
        <FormGroup className="form-group">
          <Label for="billingPostcode">{t(formInputs.postcode)}</Label>
          <Input
            type="text"
            id="billingPostcode"
            name="billing.postcode"
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.billing.postcode}
            invalid={
              !!formik.errors.billing?.postcode &&
              formik.touched.billing?.postcode
            }
          />
          <FormFeedback>{formik.errors.billing?.postcode}</FormFeedback>
        </FormGroup>
      </Col>
      <Col xs="12">
        <FormGroup className="form-group">
          <Label for="billingCity">{t(formInputs.city)}</Label>
          <Input
            type="text"
            id="billingCity"
            name="billing.city"
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.billing.city}
            invalid={
              !!formik.errors.billing?.city && formik.touched.billing?.city
            }
          />
          <FormFeedback>{formik.errors.billing?.city}</FormFeedback>
        </FormGroup>
      </Col>
      <Col xs="12">
        <FormGroup className="form-group">
          <Label for="billingCountry">{t(fields.country)}</Label>
          <SelectCountry
            name="billing.country"
            initialValue={formik.values.billing.country}
            hasError={
              !!formik.errors.billing?.country &&
              formik.touched.billing?.country
            }
            errorMessage={formik.errors.billing?.country}
            onSelect={(country: string) => {
              formik.setFieldValue("billing", {
                ...formik.values.billing,
                country
              });
              formik.setFieldTouched("billing");
            }}
            onComplete={(loading) => {
              return;
            }}
          />
        </FormGroup>
      </Col>

      <Col xs="12">
        <FormGroup className="form-group">
          <Label for="name">{t(formInputs.telephoneNumber)}</Label>
          <Input
            type="text"
            name="billing.phone"
            onChange={formik.handleChange}
            value={formik.values.billing.phone || ""}
            invalid={
              !!formik.errors.billing?.phone && formik.touched.billing?.phone
            }
          />
          <FormFeedback>{formik.errors.billing?.phone}</FormFeedback>
        </FormGroup>
      </Col>
    </>
  );
};
