import gql from "graphql-tag";

export interface PaymentMethodsResults {
  paymentGateways: {
    nodes: PaymentMethod[];
  };
}
export interface PaymentMethod {
  id: string;
  title: string;
  icon: string;
}

export interface ShippingMethodsResults {
  cart: {
    availableShippingMethods: {
      rates: ShippingMethod[];
    }[];
  };
}
export interface ShippingMethod {
  id: string;
  cost: string;
  label: string;
  methodId: string;
  instanceId: string;
}

export const PAYMENT_METHODS = gql`
  query paymentMethods {
    paymentGateways {
      nodes {
        id
        title
        icon
      }
    }
  }
`;

export const SHIPPING_METHODS = gql`
  query shippingMethods {
    cart {
      availableShippingMethods {
        rates {
          cost
          id
          instanceId
          label
          methodId
        }
      }
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation updateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      customer {
        databaseId
        billing {
          country
        }
        shipping {
          country
        }
      }
    }
  }
`;
