import React from "react";
import { useTranslation } from "react-i18next";
// import { useProductSingleHelpers } from "../lib/useProductSingleHelpers";

import { fields } from "../../lib/translation/strings";
// import { ReactComponent as MetroTruck } from "../../../assets/icons/metro-truck.svg";
// import { ReactComponent as FeatherInfo } from "../../../assets/icons/feather-info.svg";

import "./ProductSingleDetails.scss";
import { useProductSingleContext } from "../../lib/context/ProductSingleContext/ProductSingleContext";

export const ProductSingleDetails = () => {
  const { t } = useTranslation();
  const {
    product: { sku }
  } = useProductSingleContext();
  // const { getProductDetails } = useProductSingleHelpers();
  // const details = getProductDetails(productOptions);
  //
  // const getDetailsContent = (item: string[]) => {
  //   return item[1];
  // };

  return (
    <div className="ProductSingleDetails">
      <ul className="ProductSingleDetails__list">
        {sku && (
          <li>
            <p>
              <span style={{ width: 100 }} className="mr-5 d-inline-block">
                {t(fields.barcode)}:
              </span>
              {sku}
            </p>
          </li>
        )}

        {/*{details.map((item, index) => {*/}
        {/*  const content = getDetailsContent(item);*/}

        {/*  return (*/}
        {/*    <li key={`${item[0]}-product-details`}>*/}
        {/*      <p>*/}
        {/*        <span>{t(`fields${_.startCase(item[0])}`)}:</span>*/}
        {/*        {content}*/}
        {/*        {index !== details.length - 1 && ","}*/}
        {/*      </p>*/}
        {/*    </li>*/}
        {/*  );*/}
        {/*})}*/}
      </ul>
    </div>
  );
};
