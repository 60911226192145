import React from "react";
import { useTranslation } from "react-i18next";
import { useRouter } from "../../lib/hooks/useRouter";
import { Icon } from "../shared/Icon/Icon";
// import { useNotification } from "../../lib/context/NotificationContext/NotificationContext";

const removeLangFromURL = (url: string) => {
  return url.replace("/en", "");
};

export const LanguageSwitcher = () => {
  const router = useRouter();
  const { i18n } = useTranslation();
  // const n = useNotification();
  // const { t } = useTranslation();

  const switchLanguage = () => {
    const currentLanguage = i18n.language,
      newPrefix = currentLanguage === "sq" ? "/en" : "",
      newPath =
        removeLangFromURL(router.location.pathname) + router.location.search;

    i18n.changeLanguage(currentLanguage === "sq" ? "en" : "sq");
    router.push(newPrefix + newPath);
  };

  return (
    <span
      role="button"
      onClick={switchLanguage}
      className="UserNavigation__item UserNavigation__item--wishlist"
    >
      <span className="UserNavigation__item--wishlist-icon">
        <Icon icon="globe" />
      </span>
      <span className="UserNavigation__item__label">
        {i18n.language === "en" ? "SQ" : "EN"}
      </span>
    </span>
  );
};
