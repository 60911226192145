import React from "react";
import { useRegisterFormik } from "../../lib/formik/useRegisterFormik";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";
import { RegisterForm } from "./RegisterForm";
import { ThemeContainer } from "../../components/shared/ThemeContainer/ThemeContainer";

import { useMutation } from "react-apollo";
import {
  REGISTER_MUTATION,
  RegisterProps,
  RegisterResults
} from "../../queries/mutations/userMutations";
import { useNotification } from "../../lib/context/NotificationContext/NotificationContext";

import "./Register.scss";

export interface RegisterFields {
  registerName: string;
  registerLastName: string;
  registerEmail: string;
  registerTelephone: string;
  registerAddress: string;
  registerCity: string;
  registerPassword: string;
  registerConfirmPassword: string;
}

export const Register = () => {
  const authCtx = useAuthContext();
  const notification = useNotification();

  const [registerMutation] = useMutation<RegisterResults, RegisterProps>(
    REGISTER_MUTATION
  );

  const formik = useRegisterFormik({
    onSubmit: async (values) => {
      try {
        const res = await registerMutation({
          variables: {
            firstname: values.registerName,
            lastname: values.registerLastName,
            municipality: values.registerCity,
            address: values.registerAddress,
            password: values.registerPassword,
            telephone: values.registerTelephone,
            email: values.registerEmail
          }
        });
        authCtx.register(res);
      } catch (e) {
        notification.danger(e.message, true);
      }
    }
  });

  return (
    <div className="Register">
      <ThemeContainer>
        <h4>Krijo llogarinë</h4>
        <RegisterForm formik={formik} loading={formik.isSubmitting} />
      </ThemeContainer>
    </div>
  );
};
