import React from "react";
import { Col, Container, Row } from "reactstrap";

import { HeadingTitle } from "../../components/shared/HeadingTitle/HeadingTitle";
import { WholesaleForm } from "./WholesaleForm";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  errors,
  formValidation,
  messages
} from "../../lib/translation/strings";
import { useTranslation } from "react-i18next";

import "./Wholesale.scss";
import { useMutation, useQuery } from "react-apollo";
import { GET_PAGE_BY_SLUG } from "../../queries/generalQueries";
import { useRouter } from "../../lib/hooks/useRouter";
import { HandleLoadingState } from "../../components/shared/HandleLoadingState/HandleLoadingState";
import { ApolloErrorGuard } from "../../components/shared/ApolloErrorGuard/ApolloErrorGuard";
import { LoadingPlaceholder } from "../../components/shared/LoadingPlaceholder/LoadingPlaceholder";
import { useNotification } from "../../lib/context/NotificationContext/NotificationContext";
import { WHOLESALE_FORM } from "../../queries/mutations/contactMutation";

interface Props {}

export const Wholesale: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const notificationCtx = useNotification();
  const router = useRouter();
  const [wholesaleForm] = useMutation(WHOLESALE_FORM);
  const { data, loading, error } = useQuery(GET_PAGE_BY_SLUG, {
    variables: {
      slug: "wholesale"
    }
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      companyName: "",
      subject: "",
      email: "",
      phone: "",
      address: "",
      city: "",
      country: "",
      message: ""
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required(t(formValidation.field)),
      lastName: Yup.string().required(t(formValidation.field)),
      companyName: Yup.string().required(t(formValidation.field)),
      subject: Yup.string().required(t(formValidation.field)),
      email: Yup.string().required(t(formValidation.field)),
      phone: Yup.string().required(t(formValidation.field)),
      address: Yup.string().required(t(formValidation.field)),
      city: Yup.string().required(t(formValidation.field)),
      country: Yup.string().required(t(formValidation.field)),
      message: Yup.string().required(t(formValidation.field))
    }),
    onSubmit: async (values, formikHelpers) => {
      try {
        await wholesaleForm({
          variables: {
            firstName: values.firstName,
            lastName: values.lastName,
            companyName: values.companyName,
            subject: values.subject,
            email: values.email,
            phone: values.phone,
            address: values.address,
            city: values.city,
            country: values.country,
            message: values.message
          }
        });

        notificationCtx.success(t(messages.contactSuccess));

        formikHelpers.resetForm();
      } catch (error) {
        console.error("error", error);
        notificationCtx.danger(errors.wentWrong);
      }
    }
  });

  if (!loading && !data?.pageBy) {
    router.push("/404");
    return null;
  }

  return (
    <div className="Wholesale">
      <HandleLoadingState
        loading={loading}
        loadingPlaceholder={
          <Container>
            <Row>
              <Col
                xs="12"
                md="12"
                className="d-flex justify-content-center mt-5"
              >
                <LoadingPlaceholder />
              </Col>
            </Row>
          </Container>
        }
      >
        <ApolloErrorGuard error={error}>
          <Container>
            <HeadingTitle title={data?.pageBy?.title} />
            <div
              dangerouslySetInnerHTML={{ __html: data?.pageBy?.content }}
            ></div>
            <WholesaleForm
              loading={false}
              formik={formik}
              className="Wholesale-form"
            />
          </Container>
        </ApolloErrorGuard>
      </HandleLoadingState>
    </div>
  );
};
