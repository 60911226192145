import React, { useEffect, useState } from "react";
import { useProductSingleContext } from "../../lib/context/ProductSingleContext/ProductSingleContext";

import { ThemeLink } from "../shared/ThemeLink/ThemeLink";
import { ProductPrices } from "../shared/ProductPrices/ProductPrices";

export const ProductSingleContent = () => {
  const { product, variation } = useProductSingleContext();
  const productBrand = product.productBrands.nodes[0];

  const [price, setPrice] = useState(() => {
    return {
      regular: product.regularPrice,
      sale: product.salePrice
    };
  });

  useEffect(() => {
    setPrice({
      regular: variation?.regularPrice || product.regularPrice,
      sale: variation?.salePrice || product.salePrice
    });
  }, [variation]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="ProductSingleContent__header">
        <div className="top-content">
          {productBrand?.slug && (
            <ThemeLink
              to={`/brands/${productBrand?.slug}`}
              className="ProductSingle__content__brand-name"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: productBrand?.name || ""
                }}
              />
            </ThemeLink>
          )}

          {/*<WishListButton id={product.databaseId} />*/}
        </div>
        <h1 className="ProductSingle__content__title">{product?.name}</h1>
        <ProductPrices regular={price.regular} sale={price.sale} />
      </div>

      {product?.description && (
        <div className="ProductSingle__content__description">
          <div
            dangerouslySetInnerHTML={{
              __html: product.description
            }}
          />
        </div>
      )}
    </>
  );
};
