import React from "react";
import { useQuery } from "react-apollo";
import { getNewInProducts } from "../../../../queries/home";
import { ProductsList } from "../../../ProductsList/ProductsList";
import { ApolloErrorGuard } from "../../../shared/ApolloErrorGuard/ApolloErrorGuard";

export const NewIn = () => {
  const { loading, error, data } = useQuery(getNewInProducts);

  const products = data?.products?.nodes || [];

  return (
    <div className="NewIn animate__animated animate__fadeIn">
      <ApolloErrorGuard error={error}>
        <ProductsList
          loadingPlaceholderNumber={8}
          loading={loading}
          error={error?.message}
          products={products}
          isLoading={false}
          pageInfo={false}
          onLoadMore={() => {}}
        />
      </ApolloErrorGuard>
    </div>
  );
};
