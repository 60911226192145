import React from "react";
import { ApolloErrorGuard } from "../../shared/ApolloErrorGuard/ApolloErrorGuard";
import { useQuery } from "react-apollo";
import { Button } from "../../shared/Button/Button";
import { GET_EVENT_POSTS } from "../../../queries/eventsQueries";

import "./HomeBlog.scss";
import { EventProps } from "../../../types/generalTypes";
import { EventsItem } from "../../EventsItem/EventsItem";
import { HandleLoadingState } from "../../shared/HandleLoadingState/HandleLoadingState";
import { BulkLoader } from "../../shared/BulkLoader/BulkLoader";
import { ProductsLoader } from "../../ProductsList/ProductsLoader";
import { actions } from "../../../lib/translation/strings";
import { useTranslation } from "react-i18next";

export const HomeBlog = () => {
  const { t } = useTranslation();
  const { loading, data, error } = useQuery(GET_EVENT_POSTS, {
    variables: {
      limit: 3,
      after: ""
    }
  });

  const events = data?.posts.nodes;

  if (events && events.length === 0 && !loading && !error) return null;

  return (
    <div className="HomeBlog animate__animated animate__fadeIn">
      <div className="HomeBlog__title">
        <span>{t(actions.blog)}</span>
      </div>
      <div className="HomeBlog__list">
        <HandleLoadingState
          loading={loading}
          loadingPlaceholder={
            <BulkLoader length={3} component={ProductsLoader} />
          }
        >
          <ApolloErrorGuard error={error}>
            {events?.map((item: EventProps) => {
              return (
                <EventsItem
                  slug={item.slug}
                  title={item.title}
                  key={`${item.id}-archive-lisst`}
                  image={item.featuredImage?.node?.sourceUrl}
                />
              );
            })}
          </ApolloErrorGuard>
        </HandleLoadingState>
      </div>

      <div className="HomeBlog__btn">
        <Button color="primary" to="/blog">
          {t(actions.visitBlog)}
        </Button>
      </div>
    </div>
  );
};
