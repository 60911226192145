import gql from "graphql-tag";

export interface CheckoutInputData {
  clientMutationId: string;
  billing: {
    address1: string;
    city: string;
    email: string;
    postcode: string;
    country: string;
    firstName: string;
    lastName: string;
    phone: string;
  };
  shipping?: {
    country: string;
    address1: string;
    postcode: string;
    city: string;
    firstName: string;
    lastName: string;
  };
  account?: {
    username: string;
    password: string;
  };
  isPaid?: boolean;
  customerNote?: string;
  paymentMethod: string;
  shippingMethod: string;
  shipToDifferentAddress: boolean;
}

export interface BankData {
  Version: string;
  MerchantID: string;
  TerminalID: string;
  TotalAmount: number;
  Currency: string;
  locale: string;
  OrderID: number;
  PurchaseTime: string;
  PurchaseDesc: string;
  Signature: string;
}

export interface CheckoutVariables {
  inputData: CheckoutInputData;
}

export interface CheckoutResponse {
  checkout: {
    redirect: string;
    order: {
      id: string;
      databaseId: number;
    };
    customer: {
      jwtAuthToken: string;
      jwtRefreshToken: string;
      id: string;
      email: string;
      customerId: number;
      lastName: string;
      firstName: string;
      username: string;
      isBusiness: boolean;
      gender: string;
      phone: string;
      birthday: string;
      business: {
        businessName: string;
        businessType: string;
        businessNumber: string;
      };
    };
  };
}

export const CHECKOUT_ORDER = gql`
  mutation checkoutMutation($inputData: CheckoutInput!) {
    checkout(input: $inputData) {
      redirect
      order {
        id
        databaseId
      }
      customer {
        jwtAuthToken
        jwtRefreshToken
        id
        email
        databaseId
        lastName
        firstName
        username
      }
    }
  }
`;
