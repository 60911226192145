import gql from "graphql-tag";

export const GET_EVENT_POSTS = gql`
  query getEventPosts($limit: Int, $after: String) {
    posts(first: $limit, after: $after) {
      nodes {
        id
        featuredImage {
          node {
            sourceUrl(size: _630X500)
          }
        }
        excerpt
        slug
        title
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const GET_EVENT_POST = gql`
  query getEventPost($slug: String) {
    postBy(slug: $slug) {
      featuredImage {
        node {
          id
          sourceUrl(size: LARGE)
        }
      }
      excerpt
      slug
      title
      content
    }
  }
`;
