import React, { useMemo, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import { Icon } from "../../components/shared/Icon/Icon";
import { Image } from "../../components/shared/Image/Image";
import { LoadingPlaceholder } from "../../components/shared/LoadingPlaceholder/LoadingPlaceholder";
import { Product } from "../../types/productTypes";
import { useMenuSidebar } from "../../lib/context/MenuSidebarContext/MenuSidebarContext";
// import { useOutsideClickDetection } from "../../lib/hooks/useOutsideClickDetection";
import { useWishlist, WishlistAttributes } from "../../lib/hooks/useWishlist";
import "./WishlistSidebar.scss";
import { fields } from "../../lib/translation/strings";
import { useTranslation } from "react-i18next";

export const WishlistSidebar = () => {
  const ref = useRef(null);
  const wishlist = useWishlist();
  const menuSidebar = useMenuSidebar();
  const { t } = useTranslation();

  // useOutsideClickDetection(ref, () => menuSidebar.closeSidebar("wishlist"));

  const [state, setState] = useState<WishlistAttributes>({
    loading: true,
    error: undefined,
    data: []
  });

  const memoizedValue = useMemo(() => menuSidebar.wishtlist, [menuSidebar]);

  React.useEffect(() => {
    setState({
      ...state,
      loading: true
    });
    wishlist
      .getUserWishlist()
      .then((res: WishlistAttributes | undefined) => {
        if (res) {
          setState({ ...res, loading: false });
        }
      })
      .catch((e) => {
        setState({
          ...state,
          loading: false,
          error: e
        });
      });
  }, [memoizedValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRemove = async (id: number) => {
    setState({
      ...state,
      loading: true
    });
    try {
      await wishlist.removeItem(id);

      setState((prev) => {
        return {
          ...prev,
          data: state.data?.filter((item: any) => item.databaseId !== id),
          loading: false
        };
      });
    } catch (e) {
      setState({
        ...state,
        loading: true
      });
    }
  };

  return (
    <div
      ref={ref}
      className={cn(
        "WishlistSidebar",
        menuSidebar.wishtlist && "WishlistSidebar--opened"
      )}
    >
      {state.loading && (
        <div className="WishlistSidebar--updating">
          <LoadingPlaceholder text="Ju lutem prisni..." />
        </div>
      )}

      <div className="WishlistSidebar-head">
        <p>{t(fields.myList)}</p>
        <span
          role="button"
          onClick={() => menuSidebar.closeSidebar("wishlist")}
        >
          <Icon icon="x" />
        </span>
      </div>
      {state.data.length === 0 ? (
        <div className="WishlistSidebar-empty">
          Lista është e zbrazët{" "}
          <span style={{ marginLeft: 10 }}>
            <Icon icon="heart" />
          </span>
        </div>
      ) : (
        <>
          <div className="WishlistSidebar-body">
            <ul className="WishlistSidebar-list">
              {state.data?.map((product: Product) => {
                return (
                  <li
                    key={`${product?.id}`}
                    className="WishlistSidebar-list-item"
                  >
                    <NavLink to={`/product/${product?.slug}`}>
                      <div className="WishlistSidebar-list-item-image">
                        <Image
                          src={product?.image?.mediaItemUrl}
                          alt={product.name}
                        />
                      </div>
                    </NavLink>
                    <div className="WishlistSidebar-list-item-info">
                      <NavLink to={`/product/${product?.slug}`}>
                        <h4>{product?.name}</h4>
                      </NavLink>
                    </div>
                    <button
                      disabled={state.loading}
                      onClick={() => {
                        handleRemove(product.databaseId);
                      }}
                      className="WishlistSidebar-list-item-remove"
                    >
                      <Icon icon="x" />
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};
