import gql from "graphql-tag";

export interface ProductArchiveItem {
  [key: string]: any;
  id: string;
  slug: string;
  databaseId: number;
  name: string;
  regularPrice: string;
  salePrice: string;
  image: {
    srcSet: string;
    mediaItemUrl: string;
  };
}

export interface CustomAttributeOption {
  [key: string]: any;

  id: number;
  slug: string;
  name: string;
}

export interface ProductSingleAttributeProps {
  [key: string]: any;

  name: string;
  options: string[];
  id: string;
  scope: string;
  visible: boolean;
  customAttributesOptions: CustomAttributeOption[];
  enabled_options?: (string | null)[];
}
export interface ProductSingleVariationProps {
  [key: string]: any;

  node: {
    id: string;
    databaseId: number;
    regularPrice: string;
    salePrice: string;
    sku: string;
    onSale: boolean;
    stockStatus: string;
    stockQuantity: number;
    image?: {
      slug?: string;
    };
    attributes: {
      nodes: {
        name: string;
        value: string;
      }[];
    };
  };
}

export interface ProductOptions {
  [key: string]: any;

  unit?: string;
  origin?: string;
  importer?: string;
  components?: string;
  composition?: string;
  manufacturer?: string;
  video?: string;
}

export interface ProductSingleProps {
  [key: string]: any;

  id: string;
  slug: string;
  databaseId: number;
  name: string;
  description: string;
  onSale: boolean;
  sku: string;
  type: string;
  regularPrice: string;
  salePrice: string;
  stockQuantity: number;
  image: {
    slug?: string;
    sourceUrl: string;
  };
  galleryImages: {
    nodes: {
      slug?: string;
      sourceUrl: string;
    }[];
  };
  englishProductFields: {
    propertiesEn: string;
    componentsEn: string;
    compositionEn: string;
  };
  productCategories: {
    nodes: {
      slug: string;
    }[];
  };
  productBrands: {
    nodes: {
      id: string;
      name: string;
      slug: string;
    }[];
  };
  productOptions: ProductOptions;
  attributes: {
    nodes: ProductSingleAttributeProps[];
  };
  variations: {
    nodes: ProductSingleVariationProps[];
  };
}

export const VARIABLE_PRODUCT_FIELDS = gql`
  fragment VariableProductFields on VariableProduct {
    name
    stockQuantity
    salePrice(format: FORMATTED)
    regularPrice(format: FORMATTED)
    variations(first: 1000) {
      nodes {
        id
        sku
        onSale
        image {
          slug
        }
        attributes {
          nodes {
            id
            name
            value
          }
        }
        databaseId
        stockStatus
        stockQuantity
        salePrice(format: FORMATTED)
        regularPrice(format: FORMATTED)
      }
    }
  }
`;

export const GET_ARCHIVE_PRODUCTS = gql`
  query archiveQuery($limit: Int, $after: String) {
    products(first: $limit, after: $after) {
      nodes {
        id
        slug
        databaseId
        name
        ... on VariableProduct {
          name
          regularPrice(format: FORMATTED)
          salePrice(format: FORMATTED)
          stockQuantity
          productBrands(first: 10) {
            nodes {
              id
              name
              slug
            }
          }
        }
        ... on SimpleProduct {
          name
          salePrice
          regularPrice
          stockQuantity
          stockStatus
          productBrands(first: 10) {
            nodes {
              id
              name
              slug
            }
          }
        }
        image {
          id
          mediaItemUrl
          srcSet(size: LARGE)
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        offsetPagination {
          total
        }
      }
    }
  }
`;

export const GET_RELATED_PRODUCTS = gql`
  query getRelatedProducts(
    $exclude: [Int]
    $category: String
    $after: String
    $limit: Int
  ) {
    products(
      first: $limit
      after: $after
      where: { exclude: $exclude, category: $category }
    ) {
      nodes {
        id
        slug
        databaseId
        name
        ... on VariableProduct {
          name
          regularPrice(format: FORMATTED)
          salePrice(format: FORMATTED)
          stockQuantity
          productBrands(first: 10) {
            nodes {
              id
              name
              slug
            }
          }
        }
        ... on SimpleProduct {
          name
          salePrice
          regularPrice
          stockQuantity
          stockStatus
          productBrands(first: 10) {
            nodes {
              id
              name
              slug
            }
          }
        }
        image {
          id
          mediaItemUrl
          sourceUrl
          srcSet(size: LARGE)
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        offsetPagination {
          total
        }
      }
    }
  }
`;

export const GET_SINGLE_PRODUCTS = gql`
  query singleProduct($slug: String) {
    products(where: { slugIn: [$slug] }) {
      nodes {
        id
        slug
        databaseId
        name
        description
        onSale
        sku
        type
        image {
          id
          slug
          sourceUrl(size: MEDIUM_LARGE)
        }

        ... on SimpleProduct {
          name
          stockStatus
          stockQuantity
          salePrice(format: FORMATTED)
          regularPrice(format: FORMATTED)
          galleryImages(first: 10) {
            nodes {
              id
              slug
              sourceUrl(size: LARGE)
            }
          }
          attributes {
            nodes {
              name
              options
              id
              scope
              visible
              customAttributesOptions {
                id
                slug
                name
              }
            }
          }
          productOptions {
            video
            reviews {
              comment
              name
            }
          }
          productCategories {
            nodes {
              id
              slug
            }
          }
          productBrands {
            nodes {
              id
              name
              slug
            }
          }
        }
        ... on VariableProduct {
          ...VariableProductFields
          galleryImages(first: 10) {
            nodes {
              id
              slug
              sourceUrl(size: LARGE)
            }
          }
          attributes {
            nodes {
              name
              options
              id
              scope
              visible
              customAttributesOptions {
                id
                slug
                name
              }
            }
          }
          productOptions {
            video
          }
          productCategories {
            nodes {
              id
              slug
            }
          }
          productBrands {
            nodes {
              id
              name
              slug
            }
          }
        }
      }
    }
  }
  ${VARIABLE_PRODUCT_FIELDS}
`;

export const GET_USER_WISHLIST = gql`
  query getUserWishlist($products: [Int]) {
    products(first: 100, where: { include: $products }) {
      nodes {
        id
        slug
        name
        databaseId
        ... on VariableProduct {
          name
          regularPrice(format: FORMATTED)
          salePrice(format: FORMATTED)
          stockQuantity
        }
        ... on SimpleProduct {
          name
          salePrice
          regularPrice
          stockQuantity
          stockStatus
        }
        productBrands {
          nodes {
            id
            name
            slug
          }
        }
        image {
          id
          mediaItemUrl
          sourceUrl(size: LARGE)
        }
      }
    }
  }
`;

export const GET_SEARCH_QUERY = gql`
  query getSearchQuery($term: String, $limit: Int, $after: String) {
    products(first: $limit, after: $after, where: { search: $term }) {
      nodes {
        id
        slug
        databaseId
        name
        ... on VariableProduct {
          name
          regularPrice(format: FORMATTED)
          salePrice(format: FORMATTED)
          stockQuantity
        }
        ... on SimpleProduct {
          name
          salePrice
          regularPrice
          stockQuantity
          stockStatus
        }
        image {
          id
          mediaItemUrl
          sourceUrl(size: _300X210)
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
