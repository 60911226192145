import { useQuery } from "react-apollo";
import * as queries from "../../../../queries/shop";

export const useFetchMenu = () => {
  const { data, loading, error } = useQuery<
    queries.GetShopProductCategoriesResponse,
    queries.GetShopProductCategoriesVariables
  >(queries.getProductCategories, {
    variables: {
      customTaxonomy: []
    }
  });

  return {
    categories: data?.productCategories?.nodes || [],
    loading,
    error
  };
};
