import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "react-apollo";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { createApolloClient } from "./apollo/createApolloClient";
import { CartContextProvider } from "./lib/context/CartContext/CartContextProvider";
import { AuthContextProvider } from "./lib/context/AuthContext/AuthContextProvider";
import { LanguageContextProvider } from "./lib/context/LanguageContext/LanguageContextProvider";
import { MenuSidebarContextProvider } from "./lib/context/MenuSidebarContext/MenuSidebarContextProvider";
import { App } from "./App";

import "react-toastify/dist/ReactToastify.css";
import "./styles/main.scss";

const apolloClient = createApolloClient();

const element = (
  <React.Fragment>
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <LanguageContextProvider>
        <AuthContextProvider>
          <CartContextProvider>
            <MenuSidebarContextProvider>
              <App />
              <ToastContainer
                position="top-center"
                limit={5}
                autoClose={6000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </MenuSidebarContextProvider>
          </CartContextProvider>
        </AuthContextProvider>
        </LanguageContextProvider>
      </BrowserRouter>
    </ApolloProvider>
  </React.Fragment>
);

ReactDOM.render(element, document.getElementById("root"));

serviceWorker.unregister();
