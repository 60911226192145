import { useQuery } from "react-apollo";
import { CountriesResponse, GET_COUNTRIES } from "../../queries/generalQueries";

export const useAvailableCountries = () => {
  const { loading, error, data } = useQuery<CountriesResponse>(GET_COUNTRIES);

  return {
    loading,
    error,
    data: data?.allowedCountries
  };
};
