import gql from "graphql-tag";

export const GET_BRANDS_LIST = gql`
  query getBrandsList($limit: Int, $after: String) {
    productBrands(first: $limit, after: $after) {
      nodes {
        id
        slug
        name
        brandData {
          logo {
            id
            sourceUrl
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const GET_BRAND_PRODUCTS = gql`
  query getBrandProducts($slug: [String], $limit: Int, $after: String) {
    products(
      first: $limit
      after: $after
      where: {
        taxonomyFilter: { and: { taxonomy: PRODUCT_BRANDS, terms: $slug } }
      }
    ) {
      nodes {
        id
        slug
        name
        databaseId
        ... on VariableProduct {
          id
          name
          regularPrice(format: FORMATTED)
          salePrice(format: FORMATTED)
        }
        ... on SimpleProduct {
          id
          name
          salePrice
          regularPrice
        }
        image {
          id
          mediaItemUrl
          sourceUrl(size: _300X210)
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
