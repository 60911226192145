import { AuthContextUserProps } from "../../../lib/context/AuthContext/AuthContextProvider";

const getUserInitials = (user?: AuthContextUserProps) => {
  if (!user) {
    return "";
  }

  const first = user?.firstName[0] || "";
  const second = user?.lastName[0] || "";

  return `${first.toUpperCase()}${second.toUpperCase()}`;
};

export default getUserInitials;
