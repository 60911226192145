import React, { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import { Icon } from "../../../../components/shared/Icon/Icon";
import { useMenuContext } from "../../../../lib/context/MenuContext/MenuContext";
import { useOutsideClickDetection } from "../../../../lib/hooks/useOutsideClickDetection";
import { useFetchMenu } from "../lib/useFetchMenu";
import { LoadingPlaceholder } from "../../../../components/shared/LoadingPlaceholder/LoadingPlaceholder";

import "./MenuResponsive.scss";
import { actions, fields } from "../../../../lib/translation/strings";
import { useTranslation } from "react-i18next";

export const MenuResponsive = () => {
  const { t, i18n } = useTranslation();
  const ref = useRef(null);
  const menuCtx = useMenuContext();
  const { categories, loading } = useFetchMenu();

  const [opened, setOpened] = useState(false);
  const [openedCategory, setOpenedCategory] = useState("");

  useOutsideClickDetection(ref, () => menuCtx.closeSidebar());

  const handleSelectCategory = (id: string) => {
    if (openedCategory === id) {
      setOpenedCategory("");
      return;
    }

    setOpenedCategory(id);
  };

  const prefix = i18n.language === "en" ? "/en" : "";

  return (
    <div
      ref={ref}
      className={cn(
        "MenuResponsive",
        menuCtx.isOpened && "MenuResponsive__opened"
      )}
    >
      <span
        role="button"
        className="MenuResponsive__close"
        onClick={menuCtx.closeSidebar}
      >
        <Icon icon="x" />
      </span>

      <div className="MenuResponsive__list">
        <div data-root="true" className="MenuResponsive__list-item">
          <div className="MenuResponsive__list-item-toggle">
            <NavLink exact to={`${prefix}/shop`}>
              {t(actions.products)}
            </NavLink>
          </div>
        </div>
        <div
          data-root="true"
          className="MenuResponsive__list-item MenuResponsive__list-item--has-children"
        >
          <div
            data-opened={opened}
            className="MenuResponsive__list-item-toggle"
            onClick={() => setOpened(!opened)}
          >
            <span>{t(fields.categories)}</span>
            <Icon icon="chevron-down" className="chevron" />
          </div>

          {opened && (
            <>
              {loading ? (
                <LoadingPlaceholder />
              ) : (
                <>
                  {categories.map((category) => {
                    const selected = openedCategory === category.id;
                    return (
                      <div
                        key={category.id}
                        className={cn(
                          "MenuResponsive__list-item",
                          category?.children?.nodes &&
                            "MenuResponsive__list-item--has-children"
                        )}
                      >
                        <div
                          data-opened={selected}
                          className="MenuResponsive__list-item-toggle"
                        >
                          <NavLink exact to={`${prefix}/shop/${category.slug}`}>
                            {category.name}
                          </NavLink>
                          {category?.children?.nodes?.length > 0 && (
                            <span
                              role="button"
                              onClick={() => handleSelectCategory(category.id)}
                            >
                              <Icon icon="chevron-down" className="chevron" />
                            </span>
                          )}
                        </div>
                        {selected && (
                          <ul className="MenuResponsive__list-item-submenu">
                            {category?.children?.nodes.map((c) => (
                              <li key={c.id}>
                                <NavLink
                                  to={`${prefix}/shop/${category.slug}/${c.slug}`}
                                >
                                  {c.name}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    );
                  })}
                </>
              )}
            </>
          )}
        </div>
        <div data-root="true" className="MenuResponsive__list-item">
          <div className="MenuResponsive__list-item-toggle">
            <NavLink to={`${prefix}/page/about`}>{t(actions.about)}</NavLink>
          </div>
        </div>
        <div data-root="true" className="MenuResponsive__list-item">
          <div className="MenuResponsive__list-item-toggle">
            <NavLink to={`${prefix}/blog`}>{t(actions.blog)}</NavLink>
          </div>
        </div>
        <div data-root="true" className="MenuResponsive__list-item">
          <div className="MenuResponsive__list-item-toggle">
            <NavLink to={`${prefix}/wholesale`}>{t(actions.wholesale)}</NavLink>
          </div>
        </div>
        <div data-root="true" className="MenuResponsive__list-item">
          <div className="MenuResponsive__list-item-toggle">
            <NavLink to={`${prefix}/catalogue`}>{t(actions.catalogue)}</NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};
