import { RouteProps } from "react-router-dom";

import { Login } from "../pages/Login/Login";
import { Register } from "../pages/Register/Register";
import { ForgotPassword } from "../pages/ForgotPassword/ForgotPassword";
import { ResetPassword } from "../pages/ResetPassword/ResetPassword";
import { Profile } from "../pages/Profile/Profile";
import { ShopByCategory } from "../pages/Shop/ShopByCategory";
import { ShopByBrand } from "../pages/Shop/ShopByBrand";
import { Cart } from "../pages/Cart/Cart";
import { Checkout } from "../pages/Checkout/Checkout";
import { Brands } from "../pages/Brands/Brands";
import { ProductSingle } from "../pages/ProductSingle/ProductSingle";
import { Contact } from "../pages/Contact/Contact";
// import { Faq } from "../pages/Faq/Faq";
import { Wishlist } from "../pages/Wishlist/Wishlist";
import { Events } from "../pages/Events/Events";
import { EventSingle } from "../pages/Events/EventSingle";
import { Home } from "../pages/Home/Home";
import { CheckoutConfirmation } from "../pages/Checkout/CheckoutConfirmation";
import { SearchPage } from "../pages/SearchPage/SearchPage";
import { PageDefault } from "../pages/PageDefault/PageDefault";
import { Wholesale } from "../pages/Wholesale/Wholesale";
import { Catalogue } from "../pages/ Catalogue/ Catalogue";

export enum RouteType {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE"
}

interface AppRoute extends RouteProps {
  type?: RouteType;
}

export const AppRoutes: AppRoute[] = [
  // Public Routes
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: "login",
    component: Login
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: "register",
    component: Register
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: "forgot-password",
    component: ForgotPassword
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: "reset-password",
    component: ResetPassword
  },

  // Private Routes
  {
    type: RouteType.PRIVATE,
    path: "my-profile",
    component: Profile
  },

  // Normal Routes
  {
    exact: true,
    path: "/",
    component: Home
  },
  {
    exact: true,
    path: "my-cart",
    component: Cart
  },
  {
    exact: true,
    path: "checkout",
    component: Checkout
  },
  {
    path: "checkout/:orderId",
    component: CheckoutConfirmation
  },
  {
    exact: true,
    path: "brands",
    component: Brands
  },
  {
    path: "shop/:parentCategory?/:childCategory?",
    component: ShopByCategory
  },
  {
    path: "brands/:brandSlug/:parentCategory?/:childCategory?",
    component: ShopByBrand
  },
  {
    path: "product/:slug",
    component: ProductSingle
  },
  {
    path: "contact",
    component: Contact
  },
  {
    path: "wholesale",
    component: Wholesale
  },
  {
    path: "page/:slug",
    component: PageDefault
  },
  {
    path: "my-list",
    component: Wishlist
  },
  {
    path: "search",
    component: SearchPage
  },
  {
    exact: true,
    path: "blog",
    component: Events
  },
  {
    exact: true,
    path: "catalogue",
    component: Catalogue
  },
  {
    path: "blog/:slug",
    component: EventSingle
  }
];
