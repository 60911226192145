import React from "react";
import classNames from "classnames";
import { Input as RInput } from "reactstrap";
import { InputProps } from "reactstrap";
import "./Input.scss";

interface Props extends InputProps {}

export const Input: React.FC<Props> = (props) => {
  const { className, ...rest } = props;

  return <RInput {...rest} className={classNames("Input", className)} />;
};
