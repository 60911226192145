import { FormikValues } from "formik";
import { useQuery, useMutation } from "react-apollo";

import { useAuthContext } from "../context/AuthContext/AuthContext";
import { useNotification } from "../context/NotificationContext/NotificationContext";

import { GET_USER_ADDRESSES_QUERY } from "../../queries/userQueries";
import { UPDATE_ADDRESS_MUTATION } from "../../queries/mutations/userMutations";
import { useTranslation } from "react-i18next";
import { formMessages } from "../translation/strings";

export const useAddressQuery = () => {
  const { t } = useTranslation();
  const authCtx = useAuthContext();
  const notificationCtx = useNotification();

  const { loading, error, data } = useQuery(GET_USER_ADDRESSES_QUERY, {
    variables: {
      id: authCtx.user?.id
    }
  });

  const [
    updateAddressMutation,
    { loading: mutationLoading, error: mutationError, data: mutationData }
  ] = useMutation(UPDATE_ADDRESS_MUTATION);

  const updateAddress = (values: FormikValues) => {
    updateAddressMutation({
      variables: {
        id: authCtx.user?.id,
        billingFirstName: values.billing.firstName,
        billingLastName: values.billing.lastName,
        billingPostcode: values.billing.postcode,
        billingCity: values.billing.city,
        billingCountry: values.billing.country,
        billingAddress: values.billing.address1,
        billingPhone: values.billing.phone,
        shippingFirstName: values.shipping.firstName,
        shippingLastName: values.shipping.lastName,
        shippingPostcode: values.shipping.postcode,
        shippingCity: values.shipping.city,
        shippingCountry: values.shipping.country,
        shippingAddress: values.shipping.address1
      },
      update(cache, { data, errors }) {
        const { customer } = data.updateCustomer;

        if (errors || !customer) {
          notificationCtx.danger(t(formMessages.profileNotUpdated));
          return;
        }

        cache.writeQuery({
          query: GET_USER_ADDRESSES_QUERY,
          variables: {
            id: authCtx.user?.id
          },
          data: {
            customer: {
              __typename: "Customer",
              billing: {
                ...customer.billing
              },
              shipping: {
                ...customer.shipping
              }
            }
          }
        });

        notificationCtx.success(t(formMessages.profileUpdated));
      }
    });
  };

  return {
    loading,
    error,
    data,
    update: {
      loading: mutationLoading,
      error: mutationError,
      data: mutationData,
      mutation: updateAddress
    }
  };
};
