import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormGroup, Input, FormFeedback } from "reactstrap";

import { useAvailableCities } from "../../../lib/hooks/useAvailableCities";
import { actions, errors, fields } from "../../../lib/translation/strings";

interface Props {
  name: string;
  initialValue?: string;
  hasError: boolean | undefined;
  onSelect: (v: string) => void;
  errorMessage: string | undefined;
  onComplete?: (state: boolean) => void;
}

export const SelectCity = (props: Props) => {
  const {
    data: cities,
    error: errorCities,
    loading: loadingCities
  } = useAvailableCities();
  const { t } = useTranslation();

  useEffect(() => {
    if (props.onComplete) {
      props.onComplete(loadingCities);
    }
  }, [loadingCities]); // eslint-disable-line react-hooks/exhaustive-deps

  if (errorCities) {
    return <p>{t(errors.wentWrong)}</p>;
  }

  return (
    <FormGroup>
      <Input
        disabled={loadingCities}
        type="select"
        placeholder={t(fields.city)}
        id={props.name}
        invalid={props.hasError}
        onChange={(e) => props.onSelect(e.target.value)}
        defaultValue={props.initialValue ? props.initialValue : ""}
      >
        <option value="">
          {loadingCities ? "Loading..." : t(actions.selectYourCity)}
        </option>
        {cities?.map((item: string) => (
          <option key={`${item}-register-city`} value={item}>
            {item}
          </option>
        ))}
      </Input>
      <FormFeedback>{props.errorMessage}</FormFeedback>
    </FormGroup>
  );
};
