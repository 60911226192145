import React from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { useProductSingleContext } from "../../lib/context/ProductSingleContext/ProductSingleContext";

export const ProductSingleVariations = () => {
  const { t } = useTranslation();
  const { attributes, updateSelectedAttributes } = useProductSingleContext();

  return (
    <div className="ProductSingle__variations_wrapper">
      {attributes.map((attr: any) => {
        const options = Array.from(attr.values);
        const isColorAttribute = attr.name === "pa_ngjyra";

        return (
          <div
            key={`${attr.name}-single-variations`}
            className="ProductSingle__content__variations"
          >
            <span className="label mr-5">{t(attr.name)}</span>
            {isColorAttribute ? (
              <ColorBoxes
                name={attr.name}
                options={options}
                onClick={(item: string) => {
                  updateSelectedAttributes(attr.name, item);
                }}
              />
            ) : (
              <VariationSelect
                name={attr.name}
                options={options}
                onChange={(item: string) =>
                  updateSelectedAttributes(attr.name, item)
                }
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

interface SelectProps {
  name: string;
  options: any;
  onChange: (value: string) => void;
}

export const colourStyles = {
  control: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
    return {
      ...styles,
      boxShadow: "none",
      borderColor: isFocused ? "#766161 !important" : "#ccc"
    };
  },
  option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#f5ede9 !important" : null,
      color: "#000"
    };
  }
};

const VariationSelect = (props: SelectProps) => {
  const { t } = useTranslation();
  // const { selectedAttributes } = useProductSingleContext();

  // const selected =
  //   selectedAttributes
  //     ?.filter((s) => s.attribute !== "pa_ngjyra")
  //     ?.map((s) => ({ value: s.value, label: s.value })) || [];

  return (
    <Select
      styles={colourStyles}
      placeholder={t("Zgjidhni një opsion")}
      className="ProductSingle__variation-select"
      options={props.options.map((i: string) => ({ label: i, value: i }))}
      onChange={(selectedOptions: any) => {
        props.onChange(selectedOptions?.value || "");
      }}
    />
  );
};

interface ColorProps {
  name: string;
  options: any;
  onClick: (color: string) => void;
}

export const ColorBoxes = (props: ColorProps) => {
  const { t } = useTranslation();
  return (
    <Select
      styles={colourStyles}
      placeholder={t("Zgjidhni një opsion")}
      className="ProductSingle__variation-select"
      options={props.options.map((i: string) => ({ label: i, value: i }))}
      onChange={(item: any) => {
        props.onClick(item.value || "");
      }}
    />
  );
};
