import React, { useState } from "react";
// import {
//   MagnifierContainer,
//   MagnifierPreview,
//   MagnifierZoom
// } from "react-image-magnifiers";

import "./ProductSingleSliderItem.scss";
// import { Image } from "../../shared/Image/Image";

import "./ProductSingleSliderItem.scss";
import { useWindowWidth } from "../../../lib/hooks/useWindowWidth";

interface Props {
  alt?: string;
  image: string;
}

export const ProductSingleSliderItem = (props: Props) => {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 768;
  const [style, setStyle] = useState({});

  const zoomOut = {
    transform: "scale(1) translate(" + 0 + "px, " + 0 + "px)",
    transformOrigin: "0px 0px 0px"
  };

  const handleMouseMove = (e: any) => {
    e.persist();

    let widthLimit = e.target.clientWidth * 0.5;
    let heightLimit = Math.ceil(e.target.clientHeight * 0.598);

    let clientX = e.clientX - 260;
    let clientY = e.clientY - 198;

    setStyle({
      transform: `scale(${2}) translate(${-(clientX > widthLimit
        ? widthLimit
        : clientX)}px, ${-(clientY > heightLimit ? heightLimit : clientY)}px)`,
      transformOrigin: "0px 0px 0px",
      maxHeight: "unset"
    });
  };

  return isMobile ? (
    <img style={style} src={props.image} alt={props.alt} />
  ) : (
    <div
      onMouseLeave={() => setStyle(zoomOut)}
      onMouseMove={handleMouseMove}
      className="ProductSingleSlider__item"
    >
      <img style={style} src={props.image} alt={props.alt} />
      {/* <MagnifierContainer>
        <MagnifierPreview imageSrc={props.image} />
        <MagnifierZoom style={{ height: "400px" }} imageSrc={props.image} />
      </MagnifierContainer> */}
    </div>
  );
};
