import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useQuery } from "react-apollo";

import { useRouter } from "../../hooks/useRouter";
import { useNotification } from "../NotificationContext/NotificationContext";

import { GET_SINGLE_PRODUCTS } from "../../../queries/archive";

import {
  ProductSingleContext,
  ProductSingleContextType
} from "./ProductSingleContext";
import { useProductSingleHelpers } from "../../../components/lib/useProductSingleHelpers";

interface ProductSingleContextProviderProps {
  children: React.ReactNode | null;
}

export interface SelectedAttributes {
  attribute: string;
  value: string;
}

export const ProductSingleContextProvider = (
  props: ProductSingleContextProviderProps
) => {
  const notificationCtx = useNotification();
  const activeFilter = useRef<any>(undefined);
  const { query } = useRouter();
  const {
    getProductAttributes,
    getProductVariations
  } = useProductSingleHelpers();

  const { loading, error, data } = useQuery(GET_SINGLE_PRODUCTS, {
    variables: {
      slug: query.slug
    }
  });
  const product = data?.products?.nodes[0];

  const [quantity, setQuantity] = useState(1);
  const [inStock, setInStock] = useState(true);
  const [variations, setVariations] = useState([]);
  const [attributes, setAttributes] = useState<any>([]);
  const [variation, setVariation] = useState<any>(undefined);

  const [selectedAttributes, setSelectedAttributes] = useState<
    SelectedAttributes[]
  >([]);

  useEffect(() => {
    initProductStates();

    return () => {
      clearProductStates();
    };
  }, [product]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    initProductAttributes();
  }, [variations]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleVariationUpdate();
    handleAttributesFilter();
    notificationCtx.removeAll();
  }, [selectedAttributes]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleOnVariationUpdate();
  }, [variation]); // eslint-disable-line react-hooks/exhaustive-deps

  const clearProductStates = () => {
    setQuantity(1);
    setInStock(true);

    // Variable product
    setAttributes([]);
    setVariations([]);
    setVariation(undefined);
    setSelectedAttributes([]);
    activeFilter.current = undefined;
  };

  const initProductStates = () => {
    if (!product) {
      return;
    }

    setInStock(() => {
      if (product.type === "SIMPLE") {
        return product.stockStatus === "IN_STOCK" && product.stockQuantity >= 1;
      }

      return false;
    });

    if (product.type === "VARIABLE") {
      setVariations(getProductVariations(product.variations?.nodes));
    }
  };

  const initProductAttributes = () => {
    if (variations.length === 0) {
      return;
    }
    setAttributes(getProductAttributes(variations));
  };

  const getVariationsOfActiveFilter = () => {
    return variations.filter((item: any) => {
      return !!item.attributes.find(
        (i: any) =>
          i.attribute === activeFilter.current.name &&
          activeFilter.current.value === i.value
      );
    });
  };

  const handleVariationUpdate = () => {
    if (attributes.length === 0) {
      return;
    }

    if (selectedAttributes.length < attributes.length) {
      setVariation(undefined);
      return;
    }

    const newActive = _.find(variations, (o: any) =>
      _.isMatch(o.attributes, selectedAttributes)
    );

    setVariation(newActive);
  };

  const handleAttributesFilter = () => {
    const selectedAttrLength = selectedAttributes.length;

    if (selectedAttrLength === 0) {
      setAttributes(getProductAttributes(variations));
      return;
    }

    /*
      E.g. Get all variations that in attribute Size have M selected.
    */
    const filteredVariations = getVariationsOfActiveFilter();

    /*
      Step 1: Get all attributes from the filtered variations above.
      Step 2: Filter the current attribute from the array of the attributes.

      This variable will contain all the filtered attributes, except the current one which was used for filtering.
    */
    const filteredAttributes = getProductAttributes(filteredVariations).filter(
      (item: any) => item.name !== activeFilter.current.name
    );

    /*
      Get the current version of the attribute which was used for filtering.
    */
    const currentAttribute: any = attributes.filter(
      (item: any) => item.name === activeFilter.current.name
    );

    /*
      Merge the filtered attributes with the current one.
    */
    const newAttributes = [
      ...filteredAttributes,
      {
        name: currentAttribute[0].name,
        values: currentAttribute[0].values
      }
    ];

    setAttributes(_.orderBy(newAttributes, ["name"], ["asc"]));
  };

  const handleOnVariationUpdate = () => {
    if (product?.type !== "VARIABLE") {
      return;
    }

    setQuantity(1);

    if (!variation) {
      setInStock(false);
      return;
    }

    setInStock(variation.stockStatus === "IN_STOCK");
  };

  const updateSelectedAttributes = (key: string, value: string) => {
    activeFilter.current = { name: key, value };

    setSelectedAttributes((prev) => {
      let newValue = prev;

      // Remove the previous value of the attribute if it was set.
      _.remove(newValue, (item) => item.attribute === key);

      // When clicking default option(which doesn't have value), don't merge that value to the array.
      if (value === "") {
        return _.orderBy(newValue, ["attribute"], ["asc"]);
      }

      newValue = [
        ...newValue,
        {
          attribute: key,
          value
        }
      ];

      // Alphabetically order the attributes to make it possible to check for its variations.
      return _.orderBy(newValue, ["attribute"], ["asc"]);
    });
  };

  const emptySelectedAttributes = () => {
    setSelectedAttributes([]);
    setAttributes(getProductAttributes(variations));
  };

  const context: ProductSingleContextType = {
    loading,
    error,
    inStock,
    product,
    quantity,
    variation,
    attributes,
    setInStock,
    setQuantity,
    setVariation,
    selectedAttributes,
    emptySelectedAttributes,
    updateSelectedAttributes
  };

  return (
    <ProductSingleContext.Provider value={context}>
      {props.children}
    </ProductSingleContext.Provider>
  );
};
