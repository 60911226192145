import queryString from "query-string";
import { useRouter } from "./useRouter";
import {
  ProductTaxonomyEnum,
  ProductTaxonomyFilterInput
} from "../../types/generalTypes";
import { useLanguageContext } from "../context/LanguageContext/LanguageContext";

export const useShopHandlers = () => {
  const router = useRouter();
  const langCtx = useLanguageContext();
  const prefix = langCtx.getLangPrefix();

  const updateSearchParameters = (
    val: string,
    attribute: string,
    callback: (data: any) => void
  ) => {
    const query: any = queryString.parse(router.location.search);

    const value = query[attribute];

    let newQuery = {
      ...query
    };

    if (value) {
      if (value.split(",").includes(val)) {
        newQuery = {
          ...newQuery,
          [attribute]: value
            .split(",")
            .filter((f: string) => f !== val)
            .join(",")
        };
      } else {
        newQuery = {
          ...newQuery,
          [attribute]: value.concat(`,${val}`)
        };
      }
    } else {
      newQuery = {
        ...newQuery,
        [attribute]: val
      };
    }

    const newSearchParams = Object.keys(newQuery)
      .filter((f: any) => newQuery[f] !== "")
      .map((f: any) => `${f}=${newQuery[f]}`);

    router.history.replace({
      pathname: router.location.pathname,
      search: newSearchParams.join("&")
    });

    callback(newQuery);
  };

  const updateSingleQueryParameter = (
    name: string,
    value: string,
    callback: (field: string, value: string) => void
  ) => {
    const query: any = queryString.parse(router.location.search);
    const splittedValue = value.split("-");

    let newQuery = {
      ...query
    };

    //TODO: refactor this
    if (value) {
      newQuery = {
        ...newQuery,
        [name]: value
      };
    } else {
      delete newQuery[name];
    }

    const newSearchParams = Object.keys(newQuery).map(
      (f: any) => `${f}=${newQuery[f]}`
    );

    router.history.replace({
      pathname: router.location.pathname,
      search: newSearchParams.join("&")
    });

    callback(splittedValue[0], splittedValue[1]);
  };

  const updatePathname = (
    newPathName: string,
    resetSearchParameters?: boolean
  ) => {
    router.history.push({
      pathname: prefix + newPathName,
      search: resetSearchParameters ? "" : router.location.search
    });
  };

  const prepareTaxonomyFilters = (data: any, operator?: string) => {
    if (!data) {
      return [];
    }

    return Object.keys(data)
      .filter(s => {
        return Object.values(ProductTaxonomyEnum).includes(
          s as ProductTaxonomyEnum
        );
      })
      .map((sp: string) => ({
        taxonomy: sp,
        terms: data[sp] ? data[sp].split(",") : [],
        operator: operator ?? "IN"
      })) as ProductTaxonomyFilterInput[];
  };

  return {
    updateSearchParameters,
    updateSingleQueryParameter,
    prepareTaxonomyFilters,
    updatePathname
  };
};
