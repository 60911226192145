import React, { useEffect } from "react";
import { Input } from "reactstrap";

import { useTranslation } from "react-i18next";
import { useQuery } from "react-apollo";

import {
  SHIPPING_METHODS,
  ShippingMethodsResults,
  ShippingMethod as ShippingMethodProps
} from "../../../queries/checkoutQueries";
import { fields } from "../../../lib/translation/strings";
import { BulkLoader } from "../../shared/BulkLoader/BulkLoader";
import { CheckoutFormik } from "../../../lib/formik/useCheckoutFormik";
import { ApolloErrorGuard } from "../../shared/ApolloErrorGuard/ApolloErrorGuard";
import { ProductSingleLoader } from "../../../pages/ProductSingle/ProductSingleLoader";
import { HandleLoadingState } from "../../shared/HandleLoadingState/HandleLoadingState";
import { useCartContext } from "../../../lib/context/CartContext/CartContext";

import "./ShippingMethod.scss";

interface Props {
  formik: CheckoutFormik;
}

export const ShippingMethod = (props: Props) => {
  const { t } = useTranslation();
  const cartCtx = useCartContext();

  const shippingMethods = useQuery<ShippingMethodsResults>(SHIPPING_METHODS);

  useEffect(() => {
    const freeShipping = shippingMethods.data?.cart.availableShippingMethods[0].rates.find(
      (shippingMethod: ShippingMethodProps) =>
        !!shippingMethod.id.includes("free")
    );
    const defaultShipping =
      shippingMethods.data?.cart.availableShippingMethods?.[0].rates?.[0];
    if (freeShipping) {
      handleShippingChange(freeShipping);
      return;
    }
    if (defaultShipping) {
      handleShippingChange(defaultShipping);
      return;
    }
  }, [shippingMethods.data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    (async () => {
      await shippingMethods.client.cache.reset();
      await cartCtx.shippingMethodClient?.cache.reset();
      shippingMethods.refetch();
    })();
  }, [props.formik.values.billing.country]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleShippingChange = (shippingMethod: ShippingMethodProps) => {
    cartCtx.updateShippingMethod([shippingMethod.id]);
    props.formik.setFieldValue("shippingMethod", {
      id: shippingMethod.id,
      label: shippingMethod.label,
      cost: shippingMethod.cost
    });
    props.formik.setFieldTouched("shippingMethod");
  };
  return (
    <div className="ShippingMethod">
      <HandleLoadingState
        loading={shippingMethods.loading}
        loadingPlaceholder={
          <BulkLoader length={1} component={ProductSingleLoader} />
        }
      >
        <ApolloErrorGuard error={shippingMethods.error}>
          <h4 className="ShippingMethod__title">{t(fields.shippingMethod)}</h4>
          {shippingMethods.data?.cart.availableShippingMethods[0].rates?.map(
            (shippingMethod: ShippingMethodProps) => {
              return (
                <div className="ShippingMethod__item" key={shippingMethod.id}>
                  <label htmlFor={`pm-${shippingMethod.id}`}>
                    <Input
                      type="radio"
                      id={`pm-${shippingMethod.id}`}
                      name="shippingMethod"
                      checked={
                        cartCtx.chosenShippingMethods[0] === shippingMethod.id
                      }
                      value={shippingMethod.id}
                      hidden
                      onChange={() => handleShippingChange(shippingMethod)}
                    />
                    <span className="name">{shippingMethod.label}</span>
                    <span className="price">{shippingMethod.cost} €</span>
                  </label>
                </div>
              );
            }
          )}
        </ApolloErrorGuard>
      </HandleLoadingState>
    </div>
  );
};
