import React from "react";
import { ThemeLink } from "../shared/ThemeLink/ThemeLink";

import { Product } from "../../types/productTypes";

import { Image } from "../shared/Image/Image";
import { ProductPrices } from "../shared/ProductPrices/ProductPrices";
import { RemoveItemButton } from "../RemoveItemButton/RemoveItemButton";
import { WishListButton } from "../shared/WishListButton/WishListButton";

import { ProductItemWatermark } from "./ProductItemWatermark";
import "./ProductsList.scss";

interface Props {
  big?: boolean;
  product?: Product;
  noWishlistButton?: boolean;
  onRemove?: () => void;
}

export const ProductItem = (props: Props) => {
  const thumbnail = props.big
    ? props.product?.image?.mediaItemUrl
    : props.product?.image?.sourceUrl;

  const watermark = props.product?.productWatermarks?.nodes[0]?.name;

  return (
    <div className={`ProductItem${props.big ? " ProductItem--big" : ""}`}>
      {props.onRemove && <RemoveItemButton onClick={props.onRemove} />}
      <div className="ProductItem__image">
        <span className="ProductItem__image__holder">
          <ThemeLink to={`/product/${props.product?.slug}`}>
            <Image
              src={props.product?.image?.sourceUrl || thumbnail}
              alt={props.product?.name}
            />
          </ThemeLink>
        </span>
        <ProductItemWatermark tag={watermark} />

        {!props.noWishlistButton && props?.product?.databaseId && (
          <WishListButton id={props.product?.databaseId} />
        )}
      </div>
      <div className="ProductItem__content">
        {props.product?.productBrands?.nodes[0]?.slug && (
          <ThemeLink
            to={`/brands/${props.product?.productBrands.nodes[0]?.slug}`}
            className="ProductItem__brand-name"
          >
            <span
              dangerouslySetInnerHTML={{
                __html: props.product?.productBrands.nodes[0]?.name || ""
              }}
            />
          </ThemeLink>
        )}
        <ThemeLink to={`/product/${props.product?.slug}`}>
          <h4 className="ProductItem__content__title">{props.product?.name}</h4>
        </ThemeLink>
        <ProductPrices
          regular={props.product?.regularPrice ?? ""}
          sale={props.product?.salePrice ?? ""}
        />
      </div>
    </div>
  );
};
