import gql from "graphql-tag";

export type CountriesResponse = {
  allowedCountries: string[];
};

export const GET_COUNTRIES = gql`
  query getCountries {
    allowedCountries
  }
`;

export const GET_AVAILABLE_CITIES = gql`
  query getAvailableCities {
    themeOptions {
      themeOptions {
        cities
      }
    }
  }
`;

export const GET_TRANSPORT_CONTENT = gql`
  query getTransportContent {
    themeOptions {
      themeOptions {
        transport
      }
    }
  }
`;

export const GET_FOOTER_OPTIONS = gql`
  query getFooterOptions {
    generalOptions {
      generalOptions {
        footer {
          address
          email
          facebook
          instagram
          phone
          twitter
          youtube
        }
      }
    }
  }
`;

export const GET_CONTACT_OPTIONS = gql`
  query getContactOptions {
    generalOptions {
      generalOptions {
        contact {
          content
          googleApiKey
          latitude
          longitude
        }
      }
    }
  }
`;

export interface FAQItem {
  title: string;
  description: string;
}

export interface FAQResponse {
  generalOptions: {
    generalOptions: {
      faq: {
        list: FAQItem[];
      };
    };
  };
}

export const GET_FAQ = gql`
  query FAQQuery {
    generalOptions {
      generalOptions {
        faq {
          list {
            title
            description
          }
        }
      }
    }
  }
`;

export interface Page {
  title: string;
  pageFields: {
    albanianContent: string;
    englishContent: {
      title: string;
      content: string;
    };
  };
}

export const GET_PAGE_BY_ID = gql`
  query getPageByID($id: Int) {
    pages(where: { id: $id }) {
      nodes {
        title
        pageFields {
          albanianContent
          englishContent {
            title
            content
          }
        }
      }
    }
  }
`;

export const GET_PAGE_BY_SLUG = gql`
  query getPageBySlug($slug: String) {
    pageBy(uri: $slug) {
      featuredImage {
        node {
          id
          sourceUrl(size: LARGE)
        }
      }
      slug
      title
      content
    }
  }
`;
