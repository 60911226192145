import React from "react";
import { Container } from "reactstrap";

import { HomepageSlide } from "../../components/home/HomepageSlide/HomepageSlide";
import { HomeTabs } from "../../components/home/HomeTabs/HomeTabs";
import { HomeBlog } from "../../components/home/HomeBlog/HomeBlog";
import { HomeBrands } from "../../components/home/HomeBrands/HomeBrands";
import { HomeTestimonials } from "../../components/home/HomeTestimonials/HomeTestimonials";

import "./Home.scss";

export const Home = () => {
  return (
    <div className="Home">
      <HomepageSlide />

      <HomeBrands />

      <Container>
        <HomeTabs />
        <HomeBlog />
        <HomeTestimonials />
      </Container>
    </div>
  );
};
