import React, { ReactNode } from "react";
import { Col, Container, Row } from "reactstrap";
import classNames from "classnames";
import { SidebarContextProvider } from "../../../lib/context/SidebarContext/SidebarContextProvider";
// import { ShopMobileFilters } from "../ShopMobileFilters/ShopMobileFilters";
// import { MobileFiltersContextProvider } from "../../../lib/context/MobileFiltersContext/MobileFiltersContextProvider";

import "./ShopLayout.scss";

interface Props {
  sidebar: ReactNode;
  content: ReactNode;
  head?: ReactNode;
  className?: string;
}

export const ShopLayout = (props: Props) => {
  return (
    <div className={classNames("ShopLayout", props.className)}>
      <SidebarContextProvider>
        {/*<MobileFiltersContextProvider>*/}
        {/*<ShopMobileFilters />*/}

        <div className="ShopLayout__content">
          <Container className="p-0-mobile">
            <Row>
              <Col xs="12" md="3">
                {props.head && (
                  <div className="ShopLayout__head">{props.head}</div>
                )}

                {props.sidebar}
              </Col>
              <Col xs="12" md="9" style={{ marginTop: 0 }}>
                {props.content}
              </Col>
            </Row>
          </Container>
        </div>
        {/*</MobileFiltersContextProvider>*/}
      </SidebarContextProvider>
    </div>
  );
};
