import React from "react";
import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";
import { UserCart } from "./UserCart";
import { ThemeLink } from "../../../components/shared/ThemeLink/ThemeLink";
import { Icon } from "../../../components/shared/Icon/Icon";
import { useMenuSidebar } from "../../../lib/context/MenuSidebarContext/MenuSidebarContext";
import getUserInitials from "../../../components/lib/helpers/getUserInitials";
import "./UserNavigation.scss";
import { LanguageSwitcher } from "../../../components/LanguageSwitcher/LanguageSwitcher";
import { useTranslation } from "react-i18next";
import { actions, fields } from "../../../lib/translation/strings";

export const UserNavigation = () => {
  const authCtx = useAuthContext();
  const menuSidebar = useMenuSidebar();
  const { t } = useTranslation();

  return (
    <div className="UserNavigation d-flex">
      <UserCart className="UserNavigation__item" />

      <span
        role="button"
        onClick={() => menuSidebar.openSidebar("wishlist")}
        className="UserNavigation__item UserNavigation__item--wishlist"
      >
        <span className="UserNavigation__item--wishlist-icon">
          <Icon icon="heart" />
        </span>
        <span className="UserNavigation__item__label">{t(fields.myList)}</span>
      </span>

      <div className="UserNavigation__item UserNavigation__item--login">
        {authCtx.isAuthenticated ? (
          <ThemeLink
            to="/my-profile"
            activeClassName="UserNavigation__item--active"
          >
            <span className="UserNavigation__item--wishlist-icon">
              <Icon icon="user" />
            </span>

            <span
              className="UserNavigation__item__label"
              style={{ fontSize: 14 }}
            >
              {getUserInitials(authCtx.user)}
            </span>
          </ThemeLink>
        ) : (
          <ThemeLink to="/login" activeClassName="UserNavigation__item--active">
            <span className="UserNavigation__item--wishlist-icon">
              <Icon icon="user" />
            </span>
            <span className="UserNavigation__item__label">
              {t(actions.login)}
            </span>
          </ThemeLink>
        )}
      </div>

      <LanguageSwitcher />

      {authCtx.isAuthenticated && (
        <span
          className="UserNavigation__item UserNavigation__item--wishlist"
          role="button"
          onClick={() => authCtx.logout()}
        >
          <span role="button">
            <Icon icon="log-out" />
          </span>
          <span className="UserNavigation__item__label">
            {t(actions.logOut)}
          </span>
        </span>
      )}
    </div>
  );
};
