import React from "react";
import { Col, Container, Row } from "reactstrap";
// import ContentLoader from "react-content-loader";
import { RouteComponentProps } from "react-router-dom";

import "./CheckoutConfirmation.scss";
import { useQuery } from "@apollo/react-hooks";
import {
  getOrderById,
  GetOrderByIdResponse,
  GetOrderByIdVariables
} from "../../queries/ordersQueries";
import { HandleLoadingState } from "../../components/shared/HandleLoadingState/HandleLoadingState";
import { OrderSingleLoader } from "../../components/ProfilePages/ProfileOrders/OrderSingleLoader";
import { OrdersLoader } from "../../components/ProfilePages/ProfileOrders/OrdersLoader";
import { EmptyPage } from "../../components/shared/EmptyMessage/EmptyPage";
import { useTranslation } from "react-i18next";
import { errors, fields, formInputs } from "../../lib/translation/strings";
import { removeHTMLEntities } from "../../lib/helpers/removeHTMLEntities";
import { getFormatedDate } from "../../lib/helpers/getFormatedDate";

interface Props {
  orderId: string;
}

export const CheckoutConfirmation = (props: RouteComponentProps<Props>) => {
  const { t } = useTranslation();
  const orderDetails = useQuery<GetOrderByIdResponse, GetOrderByIdVariables>(
    getOrderById,
    {
      variables: {
        id: props.match.params.orderId
      }
    }
  );

  const orderData = orderDetails.data?.order;
  const orderList = orderData?.lineItems?.nodes ?? [];

  return (
    <div className="CheckoutConfirmation">
      <Container>
        <HandleLoadingState
          loading={orderDetails.loading}
          loadingPlaceholder={
            <div className="ProfileOrdersSingle" style={{ paddingTop: "55px" }}>
              <div className="ProfileOrdersSingle__left-side">
                <div
                  className="ProfileOrdersSingle__item"
                  style={{ marginTop: "-45px" }}
                >
                  <OrderSingleLoader />
                </div>
                <div className="ProfileOrdersSingle__item">
                  <OrdersLoader />
                </div>
                <div className="ProfileOrdersSingle__item">
                  <OrdersLoader />
                </div>
              </div>
              <div className="ProfileOrdersSingle__right-side" />
            </div>
          }
        >
          {!orderData ? (
            <EmptyPage
              title={t(fields.order)}
              buttonText={t(fields.myOrders)}
              redirectLink="my-profile/orders"
              description={t(errors.orderNotFound)}
            />
          ) : (
            <div className="ProfileOrdersSingle">
              <span className="ProfileOrdersSingle__order-nr">
                <strong>{t(fields.order)}: </strong>
                {orderData?.orderNumber}
              </span>
              <div className="ProfileOrdersSingle__left-side">
                <Row>
                  {orderList.map((item: any, index: number) => {
                    if (!item.product) return null;
                    return (
                      <Col xs={12} md={4} key={`${item.id}-${index}`}>
                        <div className="ProfileOrdersSingle__item">
                          <div className="ProfileOrdersSingle__thumb">
                            <img src={item.product?.image?.sourceUrl} alt="" />
                          </div>
                          <div className="ProfileOrdersSingle__content">
                            <span className="ProfileOrdersSingle__left-side__item brand">
                              {item?.product?.productBrands?.nodes
                                ?.map((brand: any) => brand.name)
                                ?.join(", ")}
                            </span>
                            <span className="ProfileOrdersSingle__left-side__item">
                              {item.product?.name}
                            </span>
                            <span className="ProfileOrdersSingle__left-side__item">
                              <strong>
                                {removeHTMLEntities(item.product.regularPrice)}
                              </strong>
                            </span>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
              <div className="ProfileOrdersSingle__right-side">
                <Row>
                  <Col xs={12} md={4}>
                    <div className="ProfileOrdersSingle__address">
                      <h4>{t(fields.billing)}: </h4>
                      {orderData.billing.firstName && (
                        <span className="ProfileOrdersSingle__right-side__contact-item">
                          <strong>{t(formInputs.name)}: </strong>
                          {orderData.billing.firstName}
                        </span>
                      )}
                      {orderData.billing.lastName && (
                        <span className="ProfileOrdersSingle__right-side__contact-item">
                          <strong>{t(formInputs.lastName)}: </strong>
                          {orderData.billing.lastName}
                        </span>
                      )}
                      {orderData.billing.address1 && (
                        <span className="ProfileOrdersSingle__right-side__contact-item">
                          <strong>{t(formInputs.address)}: </strong>
                          {orderData.billing.address1}
                        </span>
                      )}
                      {orderData.billing.state && (
                        <span className="ProfileOrdersSingle__right-side__contact-item">
                          <strong>{t(fields.country)}: </strong>
                          {orderData.billing.state}
                        </span>
                      )}
                      {orderData.billing.postcode && (
                        <span className="ProfileOrdersSingle__right-side__contact-item">
                          <strong>{t(fields.zipCode)}: </strong>
                          {orderData.billing.postcode}
                        </span>
                      )}
                      {orderData.billing.phone && (
                        <span className="ProfileOrdersSingle__right-side__contact-item">
                          <strong>{t(formInputs.telephoneNumber)}: </strong>
                          {orderData.billing.phone}
                        </span>
                      )}
                      {orderData.billing.email && (
                        <span className="ProfileOrdersSingle__right-side__contact-item">
                          <strong>{t(formInputs.email)}: </strong>
                          {orderData.billing.email}
                        </span>
                      )}
                    </div>
                  </Col>
                  <Col xs={12} md={4}>
                    <div className="ProfileOrdersSingle__address">
                      <h4>{t(fields.shipping)}: </h4>
                      {orderData.shipping.firstName && (
                        <span className="ProfileOrdersSingle__right-side__contact-item">
                          <strong>{t(formInputs.name)}: </strong>
                          {orderData.shipping.firstName}
                        </span>
                      )}
                      {orderData.shipping.lastName && (
                        <span className="ProfileOrdersSingle__right-side__contact-item">
                          <strong>{t(formInputs.lastName)}: </strong>
                          {orderData.shipping.lastName}
                        </span>
                      )}
                      {orderData.shipping.address1 && (
                        <span className="ProfileOrdersSingle__right-side__contact-item">
                          <strong>{t(formInputs.address)}: </strong>
                          {orderData.shipping.address1}
                        </span>
                      )}
                      {orderData.shipping.state && (
                        <span className="ProfileOrdersSingle__right-side__contact-item">
                          <strong>{t(fields.country)}: </strong>
                          {orderData.shipping.state}
                        </span>
                      )}
                      {orderData.shipping.postcode && (
                        <span className="ProfileOrdersSingle__right-side__contact-item">
                          <strong>{t(fields.zipCode)}: </strong>
                          {orderData.shipping.postcode}
                        </span>
                      )}
                      {orderData.shipping.phone && (
                        <span className="ProfileOrdersSingle__right-side__contact-item">
                          <strong>{t(formInputs.telephoneNumber)}: </strong>
                          {orderData.shipping.phone}
                        </span>
                      )}
                      {orderData.shipping.email && (
                        <span className="ProfileOrdersSingle__right-side__contact-item">
                          <strong>{t(formInputs.email)}: </strong>
                          {orderData.shipping.email}
                        </span>
                      )}
                    </div>
                  </Col>
                  <Col xs={12} md={4}>
                    <div className="ProfileOrdersSingle__address">
                      <h4>{t(fields.orderDetails)}: </h4>
                      {orderData.date && (
                        <span className="ProfileOrdersSingle__right-side__contact-item">
                          <strong>{t(fields.orderDate)}: </strong>
                          {getFormatedDate(orderData.date)}
                        </span>
                      )}
                      {orderData.status && (
                        <span className="ProfileOrdersSingle__right-side__contact-item">
                          <strong>{t(fields.status)}: </strong>
                          {t(orderData.status.toLowerCase())}
                        </span>
                      )}
                      {orderData.total && (
                        <span className="ProfileOrdersSingle__right-side__contact-item right">
                          <strong>{t(fields.total)}: </strong>
                          {removeHTMLEntities(orderData.total)}
                        </span>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </HandleLoadingState>
      </Container>
    </div>
  );
};
