import React from "react";
import ContentLoader from "react-content-loader";

import "./ProductSingle.scss";

export const ProductSingleLoader = () => {
  return (
    <div className="ProductSingleLoader mb-5">
      <ContentLoader
        speed={2}
        width={630}
        height={441}
        viewBox="0 0 630 441"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="33" rx="3" ry="3" width="562" height="48" />
        <rect x="0" y="94" rx="3" ry="3" width="380" height="6" />
        <rect x="0" y="111" rx="3" ry="3" width="178" height="6" />
        <rect x="0" y="141" rx="3" ry="3" width="560" height="6" />
        <rect x="0" y="155" rx="3" ry="3" width="560" height="6" />
        <rect x="0" y="169" rx="3" ry="3" width="560" height="6" />
        <rect x="0" y="209" rx="3" ry="3" width="178" height="20" />
        <rect x="0" y="247" rx="3" ry="3" width="178" height="20" />
        <rect x="0" y="287" rx="3" ry="3" width="178" height="20" />
        <rect x="0" y="8" rx="3" ry="3" width="80" height="15" />
        <rect x="0" y="337" rx="3" ry="3" width="132" height="46" />
        <rect x="148" y="336" rx="3" ry="3" width="305" height="46" />
        <rect x="462" y="336" rx="33" ry="33" width="46" height="46" />
      </ContentLoader>
    </div>
  );
};
