import React, { useState } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { NewIn } from "./tabs/NewIn";
import { OnSale } from "./tabs/OnSale";

import "./HomeTabs.scss";
import { actions } from "../../../lib/translation/strings";

const tabs = [
  {
    id: "new-in",
    label: actions.teRejat,
    component: NewIn
  },
  {
    id: "on-sale",
    label: actions.neZbritje,
    component: OnSale
  }
];

export const HomeTabs = () => {
  const { t } = useTranslation();
  const [active, setActive] = useState<string>("new-in");

  const Component = tabs.find((f) => f.id === active)?.component;

  return (
    <div className="HomeTabs" id="home-tabs">
      <ul className="HomeTabs__tabs">
        {tabs.map((tab) => (
          <li
            key={tab.id}
            onClick={() => setActive(tab.id)}
            className={cn(
              "HomeTabs__tab",
              active === tab.id && "HomeTabs__tab--active"
            )}
          >
            {t(tab.label)}
          </li>
        ))}
      </ul>
      {Component && <Component />}
    </div>
  );
};
