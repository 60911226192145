import React from "react";
import Slider from "react-slick";
import { Container } from "reactstrap";
import { Icon } from "../../shared/Icon/Icon";
import { Image } from "../../shared/Image/Image";
import { LoadingPlaceholder } from "../../shared/LoadingPlaceholder/LoadingPlaceholder";
import { ThemeLink } from "../../ThemeLink/ThemeLink";
import { useFetchMenu } from "../../../layout/Header/Menu/lib/useFetchMenu";

import "./HomeBrands.scss";

const CustomArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <span
      role="button"
      className={`CustomArrow ${className}`}
      style={{
        width: 40,
        height: 40,
        justifyContent: "center",
        alignItems: "center",
        ...style,
        display: "flex !important",
        background: "#fff",
        borderRadius: 8,
        "&:hover": {
          borderColor: "#ff681c"
        }
      }}
      onClick={onClick}
    >
      <Icon icon="chevron-right" style={{ color: "#ff681c" }} />
    </span>
  );
};

const CustomArrowLeft = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <span
      role="button"
      className={`CustomArrow ${className}`}
      style={{
        width: 40,
        height: 40,
        justifyContent: "center",
        alignItems: "center",
        ...style,
        display: "flex !important",
        background: "#fff",
        borderRadius: 8,
        "&:hover": {
          borderColor: "#ff681c"
        }
      }}
      onClick={onClick}
    >
      <Icon icon="chevron-left" style={{ color: "#ff681c" }} />
    </span>
  );
};

export const HomeBrands = () => {
  const { categories, loading } = useFetchMenu();

  const settings = {
    swipe: false,
    dots: false,
    speed: 500,
    infinite: categories?.length > 4,
    slidesToShow: 4,
    slidesToScroll: 1,
    variableWidth: true,
    prevArrow: <CustomArrowLeft />,
    nextArrow: <CustomArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          infinite: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <div className="HomeBrands" id="home-brands">
      <Container>
        {loading ? (
          <div className="d-flex justify-content-center">
            <LoadingPlaceholder />
          </div>
        ) : (
          <Slider {...settings}>
            {categories.map((category) => (
              <div key={category?.id}>
                <ThemeLink to={`/shop/${category?.slug}`}>
                  <div className="HomeBrands__img-wrapper">
                    <Image src={category?.image?.sourceUrl} />
                  </div>
                </ThemeLink>
              </div>
            ))}
          </Slider>
        )}
      </Container>
    </div>
  );
};
