import React, { useState } from "react";
import cs from "classnames";
import { useWishlist } from "../../../lib/hooks/useWishlist";
import { Button } from "../Button/Button";
import { Icon } from "../Icon/Icon";

import "./WishListButton.scss";

interface Props {
  id: number;
}

export const WishListButton = (props: Props) => {
  const { addToWishlist, isProductOnWishList } = useWishlist();
  const [isFavorite, setIsFavorite] = useState(isProductOnWishList(props.id));

  const handleClick = () => {
    addToWishlist(props.id);
    setIsFavorite(true);
  };

  return (
    <Button
      color="pink"
      className={cs("WishListButton", isFavorite && "WishListButton--fill")}
      onClick={handleClick}
    >
      <Icon icon="heart" />
    </Button>
  );
};
