import { FormikValues } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, FormFeedback, FormGroup, Label, Row } from "reactstrap";
import { Input } from "../../components/shared/Input/Input";
import { BlockButton } from "../../components/shared/BlockButton/BlockButton";
import { actions, formInputs } from "../../lib/translation/strings";
import { SelectCity } from "../../components/shared/SelectCity/SelectCity";

interface Props {
  loading: boolean;
  formik: FormikValues;
  className?: string;
}

export const WholesaleForm = (props: Props) => {
  const { formik } = props;
  const { t } = useTranslation();

  return (
    <Form onSubmit={formik.handleSubmit} className={props.className}>
      <Row>
        <Col xs="12" sm="6">
          <FormGroup className="form-group">
            <Label for="firstName">{t(formInputs.firstName)}</Label>
            <Input
              id="name"
              type="text"
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              className="form-control"
              invalid={!!formik.errors.firstName && formik.touched.firstName}
            />
            <FormFeedback>{formik.errors.firstName}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs="12" sm="6">
          <FormGroup className="form-group">
            <Label for="lastName">{t(formInputs.lastName)}</Label>
            <Input
              id="name"
              type="text"
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              className="form-control"
              invalid={!!formik.errors.lastName && formik.touched.lastName}
            />
            <FormFeedback>{formik.errors.lastName}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs="12" sm="6">
          <FormGroup>
            <Label for="companyName">{t(formInputs.companyName)}</Label>
            <Input
              id="companyName"
              type="text"
              name="companyName"
              className="form-control-custom"
              value={formik.values.companyName}
              onChange={formik.handleChange}
              invalid={
                !!formik.errors.companyName && formik.touched.companyName
              }
            />
            <FormFeedback>{formik.errors.companyName}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs="12" sm="6">
          <FormGroup>
            <Label for="subject">{t(formInputs.subject)}</Label>
            <Input
              type="select"
              name="subject"
              className="form-control-custom"
              value={formik.values.subject}
              onChange={formik.handleChange}
              invalid={!!formik.errors.subject && formik.touched.subject}
            >
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </Input>
            <FormFeedback>{formik.errors.subject}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs="12" sm="6">
          <FormGroup>
            <Label for="email">{t(formInputs.email)}</Label>
            <Input
              id="email"
              type="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              className="form-control-custom"
              invalid={!!formik.errors.email && formik.touched.email}
            />
            <FormFeedback>{formik.errors.email}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs="12" sm="6">
          <FormGroup>
            <Label for="phone">{t(formInputs.phone)}</Label>
            <Input
              id="phone"
              type="text"
              name="phone"
              className="form-control-custom"
              value={formik.values.phone}
              onChange={formik.handleChange}
              invalid={!!formik.errors.phone && formik.touched.phone}
            />
            <FormFeedback>{formik.errors.phone}</FormFeedback>
          </FormGroup>
        </Col>

        <Col xs="12" sm="4">
          <FormGroup>
            <Label for="address">{t(formInputs.address)}</Label>
            <Input
              id="address"
              type="text"
              name="address"
              className="form-control-custom"
              value={formik.values.address}
              onChange={formik.handleChange}
              invalid={!!formik.errors.address && formik.touched.address}
            />
            <FormFeedback>{formik.errors.address}</FormFeedback>
          </FormGroup>
        </Col>

        <Col xs="12" sm="4">
          <FormGroup>
            <Label for="billingCity">{t(formInputs.city)}</Label>
            <SelectCity
              name="city"
              initialValue={formik.values.city}
              hasError={!!formik.errors?.city && formik.touched?.city}
              errorMessage={formik.errors?.city}
              onSelect={(city: string) => {
                formik.setFieldValue("city", city);
                formik.setFieldTouched("city");
              }}
            />
            <FormFeedback>{formik.errors.city}</FormFeedback>
          </FormGroup>
        </Col>

        <Col xs="12" sm="4">
          <FormGroup>
            <Label for="country">{t(formInputs.country)}</Label>
            <Input
              id="country"
              type="text"
              name="country"
              className="form-control-custom"
              value={formik.values.country}
              onChange={formik.handleChange}
              invalid={!!formik.errors.country && formik.touched.country}
            />
            <FormFeedback>{formik.errors.country}</FormFeedback>
          </FormGroup>
        </Col>

        <Col xs="12" sm="12">
          <FormGroup>
            <Label for="message">{t(formInputs.message)}</Label>
            <Input
              id="message"
              type="textarea"
              name="message"
              className="form-control-custom"
              value={formik.values.message}
              onChange={formik.handleChange}
              invalid={!!formik.errors.message && formik.touched.message}
            />
            <FormFeedback>{formik.errors.message}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs="12">
          <FormGroup>
            <BlockButton
              type="submit"
              disabled={props.loading}
              className=" Contact__btn_submit w-100-mobile"
            >
              {props.loading ? t(actions.saving) : t(actions.send)}
            </BlockButton>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};
