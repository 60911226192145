export interface EventProps {
  id: string;
  featuredImage: {
    node: {
      sourceUrl: string;
    };
  };
  excerpt: string;
  slug: string;
  title: string;
}

export interface EventQueryProps {
  posts: {
    nodes: Array<EventProps>;
    pageInfo: {
      endCursor: string;
      hasNextPage: string;
    };
  };
}

export interface PageInfo {
  endCursor?: string;
  startCursor?: string;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
}

export enum ProductTaxonomyEnum {
  PABLUETOOTH = "PABLUETOOTH",
  PAMADHESIA = "PAMADHESIA",
  PAMOSHA = "PAMOSHA",
  PANGJYRA = "PANGJYRA",
  PAGJINIA = "PAGJINIA",
  PRODUCTBRAND = "PRODUCT_BRANDS",
  PRODUCTCATEGORY = "PRODUCT_CAT",
  PRODUCTTAG = "PRODUCT_TAG",
  PRODUCTTYPE = "PRODUCT_TYPE",
  SHIPPINGCLASS = "SHIPPINGCLASS",
  VENDOR = "VENDOR",
  VISIBLEPRODUCT = "VISIBLEPRODUCT"
}

export enum TaxonomyOperatorEnum {
  AND = "AND",
  EXISTS = "EXISTS",
  IN = "IN",
  NOT_EXISTS = "NOT_EXISTS",
  NOT_IN = "NOT_IN"
}

export interface ProductTaxonomyFilterInput {
  taxonomy: ProductTaxonomyEnum;
  terms: string | string[];
  operator?: TaxonomyOperatorEnum;
}

export interface Coupon {
  code: string;
  discountAmount: number;
  discountTax: string;
}

export interface AttributeTerms {
  count: number;
  id: string;
  name: string;
  slug: string;
}

export interface AttributeFilter {
  id: string;
  name: string;
  slug: string;
  label: string;
  terms: AttributeTerms[];
}

export interface ChosenAttributesInput extends ProductTaxonomyFilterInput {}
