import React from "react";
import Slider from "react-slick";
import { Icon } from "../../shared/Icon/Icon";
import { useQuery } from "@apollo/react-hooks";
import {
  getTestimonialsQuery,
  GetTestimonialsResponse
} from "../../../queries/testimonials";
import { LoadingPlaceholder } from "../../shared/LoadingPlaceholder/LoadingPlaceholder";

import "./HomeTestimonials.scss";
import { Image } from "../../shared/Image/Image";

const CustomArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <span
      role="button"
      className={`CustomArrow ${className}`}
      style={{
        cursor: "pointer",
        width: 40,
        height: 40,
        justifyContent: "center",
        alignItems: "center",
        ...style,
        "&:hover": {
          borderColor: "#ff681c"
        }
      }}
      onClick={onClick}
    >
      <Icon icon="chevron-right" style={{ color: "#000" }} />
    </span>
  );
};

const CustomArrowLeft = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <span
      role="button"
      className={`CustomArrow ${className}`}
      style={{
        width: 40,
        cursor: "pointer",
        height: 40,
        justifyContent: "center",
        alignItems: "center",
        ...style,
        display: "flex !important",
        "&:hover": {
          borderColor: "#ff681c"
        }
      }}
      onClick={onClick}
    >
      <Icon icon="chevron-left" style={{ color: "#000" }} />
    </span>
  );
};

interface Props {}

export const HomeTestimonials: React.FC<Props> = (props) => {
  const { loading, data } = useQuery<GetTestimonialsResponse, undefined>(
    getTestimonialsQuery
  );

  const testimonials = data?.testimonials.nodes ?? [];

  const settings = {
    className: "center",
    centerMode: true,
    pauseOnHover: true,
    centerPadding: "60px",
    slidesToShow: 3,
    speed: 800,
    prevArrow: <CustomArrowLeft />,
    nextArrow: <CustomArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="HomeTestimonials">
      {loading ? (
        <div className="d-flex justify-content-center">
          <LoadingPlaceholder />
        </div>
      ) : (
        <Slider {...settings}>
          {testimonials.map((t) => (
            <div key={t.id} className="HomeTestimonials-item">
              <Image src={t.featuredImage?.node?.mediaItemUrl} />
              <h4>{t.title}</h4>
              <div dangerouslySetInnerHTML={{ __html: t.content }}></div>
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
};
